import { Box, alpha, Stack, Typography } from "@mui/material";

import { useUserStore } from "src/stores/user/user.store";

type Props = {
    children: React.ReactNode;
};

export default function PointsLayout({ children }: Props) {
    const { wallet } = useUserStore()
    return(
        <>
            <Stack 
                position='fixed'
                direction='row' 
                justifyContent='space-between' 
                p={12} 
                alignItems='center'
                width='100%'
                zIndex={1}
                bgcolor={(theme) =>  theme.palette.common.white}
                sx={{
                    borderBottom: (theme) => `1px solid ${alpha(theme.palette.common.black, 0.2)}`
                }}
            >
                <Typography variant="paragraphP700">
                    Meus pontos
                </Typography>
                <Typography variant="h5">
                    {wallet?.balance ? wallet?.balance.toLocaleString() : 0} pts
                </Typography> 
            </Stack>
            <Box pt={37} width='100%' height='100%'>
                {children}
            </Box>
        </>
    )
}