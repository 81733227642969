type IconProps = {
  selected?: boolean;
  highlighted?: boolean;
};

export default function HomeLayoutIcon({ selected, highlighted }: IconProps) {
  if (selected) {
    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.5 32V21.8267C32.5 21.5106 32.3505 21.2131 32.0969 21.0244L24.5969 15.4441C24.2426 15.1805 23.7574 15.1805 23.4031 15.4441L15.9031 21.0244C15.6495 21.2131 15.5 21.5106 15.5 21.8267V32C15.5 32.5523 15.9477 33 16.5 33H20.1667C20.719 33 21.1667 32.5523 21.1667 32V29.1622C21.1667 28.0576 22.0621 27.1622 23.1667 27.1622H24.8333C25.9379 27.1622 26.8333 28.0576 26.8333 29.1622V32C26.8333 32.5523 27.281 33 27.8333 33H31.5C32.0523 33 32.5 32.5523 32.5 32Z"
          fill="#112645"
          stroke="#112645"
          strokeWidth="1.75"
        />
      </svg>
    );
  }
  if (highlighted) {
    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.5 32V21.8267C32.5 21.5106 32.3505 21.2131 32.0969 21.0244L24.5969 15.4441C24.2426 15.1805 23.7574 15.1805 23.4031 15.4441L15.9031 21.0244C15.6495 21.2131 15.5 21.5106 15.5 21.8267V32C15.5 32.5523 15.9477 33 16.5 33H20.1667C20.719 33 21.1667 32.5523 21.1667 32V29.1622C21.1667 28.0576 22.0621 27.1622 23.1667 27.1622H24.8333C25.9379 27.1622 26.8333 28.0576 26.8333 29.1622V32C26.8333 32.5523 27.281 33 27.8333 33H31.5C32.0523 33 32.5 32.5523 32.5 32Z"
          stroke="#EAEEF5"
          strokeWidth="1.75"
        />
      </svg>
    );
  }
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.5 32V21.8267C32.5 21.5106 32.3505 21.2131 32.0969 21.0244L24.5969 15.4441C24.2426 15.1805 23.7574 15.1805 23.4031 15.4441L15.9031 21.0244C15.6495 21.2131 15.5 21.5106 15.5 21.8267V32C15.5 32.5523 15.9477 33 16.5 33H20.1667C20.719 33 21.1667 32.5523 21.1667 32V29.1622C21.1667 28.0576 22.0621 27.1622 23.1667 27.1622H24.8333C25.9379 27.1622 26.8333 28.0576 26.8333 29.1622V32C26.8333 32.5523 27.281 33 27.8333 33H31.5C32.0523 33 32.5 32.5523 32.5 32Z"
        stroke="#112645"
        strokeWidth="1.75"
      />
    </svg>
  );
}
