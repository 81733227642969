import { useState, useEffect, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { SplashScreen } from 'src/components/loading-screen';

import { useAuthStore } from '../auth.store';

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { isLoading } = useAuthStore();

  return <>{isLoading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

function Container({ children }: Props) {
  const router = useRouter();

  const { isAuthenticated } = useAuthStore();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!isAuthenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();
      let params;
      if(window.location.search) {
        params = `${window.location.search}&${searchParams}`
      } else {
        params = `?${searchParams}`
      }
      
      const href = `${paths.register.root}${params}`;
      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [isAuthenticated, router]);

  useEffect(() => {
    check();
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
