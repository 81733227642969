export default function MyProfile() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 3.75C4.30964 3.75 3.75 4.30964 3.75 5V19C3.75 19.6904 4.30964 20.25 5 20.25H9C9.41421 20.25 9.75 20.5858 9.75 21C9.75 21.4142 9.41421 21.75 9 21.75H5C3.48122 21.75 2.25 20.5188 2.25 19V5C2.25 3.48122 3.48122 2.25 5 2.25H19C20.5188 2.25 21.75 3.48122 21.75 5V8C21.75 8.41421 21.4142 8.75 21 8.75C20.5858 8.75 20.25 8.41421 20.25 8V5C20.25 4.30964 19.6904 3.75 19 3.75H5ZM6.25 7.062C6.25 6.64779 6.58579 6.312 7 6.312H15C15.4142 6.312 15.75 6.64779 15.75 7.062C15.75 7.47621 15.4142 7.812 15 7.812H7C6.58579 7.812 6.25 7.47621 6.25 7.062ZM6.25 10.562C6.25 10.1478 6.58579 9.812 7 9.812H12C12.4142 9.812 12.75 10.1478 12.75 10.562C12.75 10.9762 12.4142 11.312 12 11.312H7C6.58579 11.312 6.25 10.9762 6.25 10.562ZM17.255 12.25C16.5646 12.2472 16.0028 12.8046 16 13.495L16 13.5C16 14.1904 16.5596 14.75 17.25 14.75C17.9384 14.75 18.4972 14.1934 18.5 13.505C18.5027 12.8146 17.9453 12.2528 17.255 12.25ZM14.5 13.489C14.5061 11.9702 15.7422 10.7439 17.261 10.75C18.7797 10.7561 20.006 11.9922 20 13.511C19.9939 15.0255 18.7645 16.25 17.25 16.25C15.7312 16.25 14.5 15.0188 14.5 13.5L14.5 13.489ZM8.5 14.812C8.08579 14.812 7.75 15.1478 7.75 15.562C7.75 15.9762 8.08579 16.312 8.5 16.312C8.91421 16.312 9.25 15.9762 9.25 15.562C9.25 15.1478 8.91421 14.812 8.5 14.812ZM6.25 15.562C6.25 14.3194 7.25736 13.312 8.5 13.312C9.74264 13.312 10.75 14.3194 10.75 15.562C10.75 16.8046 9.74264 17.812 8.5 17.812C7.25736 17.812 6.25 16.8046 6.25 15.562ZM15.5918 17.25L15.594 17.25L18.9081 17.25C20.4758 17.2545 21.7455 18.5242 21.75 20.0919L21.75 20.094L21.75 20.5C21.75 21.1904 21.1904 21.75 20.5 21.75H14C13.3096 21.75 12.75 21.1904 12.75 20.5V20.094L12.75 20.0919C12.7545 18.5242 14.0242 17.2545 15.5918 17.25ZM18.9048 18.75H15.5952C14.8534 18.7526 14.2526 19.3534 14.25 20.0952V20.25H20.25V20.095C20.2473 19.3532 19.6465 18.7526 18.9048 18.75Z"
          fill="#112645"
        />
      </g>
    </svg>
  );
}
