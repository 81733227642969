import { alpha } from '@mui/material/styles';

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lightest: string;
    lighter: string;
    darker: string;
    darkest: string;
  }
  interface PaletteColor {
    lightest: string;
    lighter: string;
    darker: string;
    darkest: string;
  }

  interface CommonColors {
    transparent: string;
    neutral: string;
    hover: string;
  }
  
  interface Palette {
    blueBrand: {
        0: string,
      100: string,
      150: string,
      200: string,
      300: string,
      400: string,
      500: string,
    };
    redBrand: {
        0: string,
      100: string,
      200: string,
      300: string,
      400: string,
      500: string,
    };
    greenBrand: {
        0: string,
      100: string,
      200: string,
      300: string,
      400: string,
      500: string,
    };
    yellowBrand: {
        0: string,
      100: string,
      200: string,
      300: string,
    };
  }
  interface PaletteOptions {
    blueBrand?: {
        0: string,
      100: string,
      150: string,
      200: string,
      300: string,
      400: string,
      500: string,
    };
    redBrand?: {
        0: string,
      100: string,
      200: string,
      300: string,
      400: string,
      500: string,
    };
    greenBrand?: {
        0: string,
      100: string,
      200: string,
      300: string,
      400: string,
      500: string,
    };
    yellowBrand?: {
        0: string,
      100: string,
      200: string,
      300: string,
    };
  }
}

// SETUP COLORS

export const grey = {
  0: '#FFFFFF',
  100: '#EDEDED',
  200: '#D9D9D9',
  300: '#BABABA',
  400: '#959595',
  500: '#6E6E6E',
  600: '#251E1E',
  700: '#251E1E',
  800: '#251E1E',
  900: '#251E1E',
};

export const blueBrand = {
  0: '#EAEEF5',
  100: '#CADAF2',
  150: '#B5DCF0',
  200: '#6493D8',
  300: '#3069BF',
  400: '#214983',
  500: '#112645',
};

export const redBrand = {
  0: '#FCEEEE',
  100: '#F3C3C4',
  200: '#FF0000',
  300: '#D22E2D',
  400: '#931F1F',
  500: '#541212',
};

export const greenBrand = {
  0: '#DFEEC4',
  100: '#BFDD88',
  200: '#A0CC4D',
  300: '#55AE69',
  400: '#769E2E',
  500: '#4A631D',
};

export const yellowBrand = {
  0: '#FBE0BE',
  100: '#F6BD74',
  200: '#F29A2C',
  300: '#C5730C',
};

export const primary = {
  lightest: '#EAEEF5',
  lighter: '#CADAF2',
  light: '#B5DCF0',
  main: '#6493D8',
  dark: '#3069BF',
  darker: '#214983',
  darkest: '#112645',
  contrastText: '#FFFFFF',
};

export const secondary = {
  lightest: '#FCEEEE',
  lighter: '#F3C3C4',
  light: '#FF0000',
  main: '#D22E2D',
  dark: '#931F1F',
  darker: '#541212',
  darkest: '#541212',
  contrastText: grey[600],
};

export const info = {
  lightest: '#D0F2FF',
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  darkest: '#04297A',
  contrastText: '#FFFFFF',
};

export const success = {
  lightest: '#DFEEC4',
  lighter: '#BFDD88',
  light: '#A0CC4D',
  main: '#55AE69',
  dark: '#769E2E',
  darker: '#4A631D',
  darkest: '#4A631D',
  contrastText: grey[600],
};

export const warning = {
  lightest: '#FBE0BE',
  lighter: '#FBE0BE',
  light: '#F6BD74',
  main: '#F29A2C',
  dark: '#C5730C',
  darker: '#C5730C',
  darkest: '#C5730C',
  contrastText: '#232A33',
};

export const error = {
  lightest: '#FCEEEE',
  lighter: '#F3C3C4',
  light: '#FF0000',
  main: '#D22E2D',
  dark: '#931F1F',
  darker: '#541212',
  darkest: '#541212',
  contrastText: grey[600],
};

export const common = {
  black: '#000000',
  white: '#FFFFFF',
  transparent: 'transparent',
  neutral: '#474747',
  hover: '#888888'
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  blueBrand,
  redBrand,
  greenBrand,
  yellowBrand,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(grey[500], 0.24),
  action,
};

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...base,
    mode: 'light',
    text: {
      primary: grey[800],
      secondary: grey[600],
      disabled: grey[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: grey[100],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };

  const dark = {
    ...base,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: grey[500],
      disabled: grey[600],
    },
    background: {
      paper: grey[800],
      default: grey[900],
      neutral: alpha(grey[500], 0.16),
    },
    action: {
      ...base.action,
      active: grey[500],
    },
  };

  return mode === 'light' ? light : dark;
}
