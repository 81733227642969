export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    fontSecondaryFamily: React.CSSProperties['fontFamily'];
    fontWeightSemiBold: React.CSSProperties['fontWeight'];
    paragraphXL400: React.CSSProperties;
    paragraphXL500: React.CSSProperties;
    paragraphL400: React.CSSProperties;
    paragraphL500: React.CSSProperties;
    paragraphM400: React.CSSProperties;
    paragraphM500: React.CSSProperties;
    paragraphM700: React.CSSProperties;
    paragraphP400: React.CSSProperties;
    paragraphP500: React.CSSProperties;
    paragraphP700: React.CSSProperties;
    labelL: React.CSSProperties;
    labelM: React.CSSProperties;
    labelP: React.CSSProperties;
    labelXS: React.CSSProperties;
    inputL500: React.CSSProperties;
    inputL700: React.CSSProperties;
    inputM500: React.CSSProperties;
    inputM700: React.CSSProperties;
    inputS500: React.CSSProperties;
    inputS700: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    paragraphXL400: true;
    paragraphXL500: true;
    paragraphL400: true;
    paragraphL500: true;
    paragraphM400: true;
    paragraphM500: true;
    paragraphM700: true;
    paragraphP400: true;
    paragraphP500: true;
    paragraphP700: true;
    labelL: true;
    labelM: true;
    labelP: true;
    labelXS: true;
    inputL500: true;
    inputL700: true;
    inputM500: true;
    inputM700: true;
    inputS500: true;
    inputS700: true;
  }
}

export const primaryFont = 'Bebas Neue, sans-serif';
export const secondaryFont = 'Inter, sans-serif';

export const typography = {
  fontFamily: primaryFont,
  fontSecondaryFamily: secondaryFont,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  h1: {
    lineHeight: 1.2,
    fontSize: pxToRem(34),
    ...responsiveFontSizes({ sm: 34, md: 34, lg: 34 }),
  },
  h2: {
    lineHeight: 1.2,
    fontSize: pxToRem(30),
    ...responsiveFontSizes({ sm: 30, md: 30, lg: 30 }),
  },
  h3: {
    lineHeight: 1.2,
    fontSize: pxToRem(28),
    ...responsiveFontSizes({ sm: 28, md: 28, lg: 28 }),
  },
  h4: {
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 24, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.4,
    fontSize: pxToRem(18),
    fontFamily: secondaryFont,
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 1.4,
    fontSize: pxToRem(16),
    fontFamily: secondaryFont,
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 }),
  },
  paragraphXL400:{
    fontWeight: 400,
    lineHeight: 1.4,
    fontSize: pxToRem(18),
    fontFamily: secondaryFont,
  },
  paragraphXL500:{
    fontWeight: 500,
    lineHeight: 1.4,
    fontSize: pxToRem(18),
    fontFamily: secondaryFont,
  },
  paragraphL400:{
    fontWeight: 400,
    lineHeight: 1.4,
    fontSize: pxToRem(16),
    fontFamily: secondaryFont,
  },
  paragraphL500:{
    fontWeight: 500,
    lineHeight: 1.4,
    fontSize: pxToRem(16),
    fontFamily: secondaryFont,
  },
  paragraphM400:{
    fontWeight: 400,
    lineHeight: 1.4,
    fontSize: pxToRem(14),
    fontFamily: secondaryFont,
  },
  paragraphM500:{
    fontWeight: 500,
    lineHeight: 1.4,
    fontSize: pxToRem(14),
    fontFamily: secondaryFont,
  },
  paragraphM700:{
    fontWeight: 700,
    lineHeight: 1.4,
    fontSize: pxToRem(14),
    fontFamily: secondaryFont,
  },
  paragraphP400:{
    fontWeight: 400,
    lineHeight: 1.4,
    fontSize: pxToRem(12),
    fontFamily: secondaryFont,
  },
  paragraphP500:{
    fontWeight: 500,
    lineHeight: 1.4,
    fontSize: pxToRem(12),
    fontFamily: secondaryFont,
  },
  paragraphP700:{
    fontWeight: 700,
    lineHeight: 1.4,
    fontSize: pxToRem(12),
    fontFamily: secondaryFont,
  },
  labelL:{
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: pxToRem(16),
    fontFamily: secondaryFont,
  },
  labelM:{
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: pxToRem(14),
    fontFamily: secondaryFont,
  },
  labelP:{
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: pxToRem(12),
    fontFamily: secondaryFont,
  },
  labelXS:{
    fontWeight: 500,
    lineHeight: 1.2,
    fontSize: pxToRem(10),
    fontFamily: secondaryFont,
  },
  inputL500:{
    fontWeight: 500,
    lineHeight: 1.4,
    fontSize: pxToRem(14),
    fontFamily: secondaryFont,
  },
  inputL700:{
    fontWeight: 700,
    lineHeight: 1.4,
    fontSize: pxToRem(14),
    fontFamily: secondaryFont,
  },
  inputM500:{
    fontWeight: 500,
    lineHeight: 1.4,
    fontSize: pxToRem(12),
    fontFamily: secondaryFont,
  },
  inputM700:{
    fontWeight: 700,
    lineHeight: 1.4,
    fontSize: pxToRem(12),
    fontFamily: secondaryFont,
  },
  inputS500:{
    fontWeight: 500,
    lineHeight: 1.2,
    fontSize: pxToRem(10),
    fontFamily: secondaryFont,
  },
  inputS700:{
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: pxToRem(10),
    fontFamily: secondaryFont,
  },


  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    fontWeight: 500,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    spacing: 1.2,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 1.4,
    fontSize: pxToRem(14),
    fontFamily: secondaryFont,
    textTransform: 'none',
  },
} as const;
