import { left, right, Either } from 'src/utils/either';

import { HttpTypes } from '.';
import {
  NotFoundError,
  BadRequestError,
  ConnectionError,
  UnexpectedError,
  AccessDeniedError,
  InternalServerError,
  TooManyRequestsError,
} from './errors';

export const httpResponseHandler = (response: any): Either<Error, any> => {
  switch (response.statusCode) {
    case HttpTypes.HttpStatusCode.ok: {
      return right(response.body);
    }
    case HttpTypes.HttpStatusCode.badRequest: {
      return left(new BadRequestError(response.body));
    }
    case HttpTypes.HttpStatusCode.unauthorized: {
      return left(new AccessDeniedError());
    }
    case HttpTypes.HttpStatusCode.forbidden: {
      return left(new AccessDeniedError({code: response?.statusCode, message: response?.body?.message }));
    }
    case HttpTypes.HttpStatusCode.connection: {
      return left(new ConnectionError());
    }
    case HttpTypes.HttpStatusCode.tooManyRequests: {
      return left(new TooManyRequestsError());
    }
    case HttpTypes.HttpStatusCode.notFound: {
      return left(new NotFoundError(response.body));
    }
    case HttpTypes.HttpStatusCode.serverError: {
      return left(new InternalServerError());
    }
    default: {
      return left(new UnexpectedError(response.statusCode));
    }
  }
};
