import { useUserStore } from 'src/stores/user/user.store';

import { useAuthStore } from './auth.store';

export const useAuth = () => {
  const { accessToken, refreshToken, setIsAuthenticated } = useAuthStore();
  const { userData, myBalance } = useUserStore()

  const initializeAuth = () => {
    if (accessToken && refreshToken) {
      userData().then(() => {
        setIsAuthenticated(true);
        myBalance();
      }).catch(() => {
        setIsAuthenticated(false);
      })
    } else {
      setIsAuthenticated(false);
    }
  };

  const useAuthHook: any = {
    initializeAuth,
  };

  return useAuthHook;
};
