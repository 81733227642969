export default function MyPoints() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2929 1.29289C16.6834 0.902369 17.3166 0.902369 17.7071 1.29289L21.7071 5.29289C22.0976 5.68342 22.0976 6.31658 21.7071 6.70711L17.7071 10.7071C17.3166 11.0976 16.6834 11.0976 16.2929 10.7071C15.9024 10.3166 15.9024 9.68342 16.2929 9.29289L19.5858 6L16.2929 2.70711C15.9024 2.31658 15.9024 1.68342 16.2929 1.29289Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 7C6.20435 7 5.44129 7.31607 4.87868 7.87868C4.31607 8.44129 4 9.20435 4 10V11C4 11.5523 3.55228 12 3 12C2.44772 12 2 11.5523 2 11V10C2 8.67392 2.52678 7.40215 3.46447 6.46447C4.40215 5.52678 5.67392 5 7 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H7Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.70711 13.2929C8.09763 13.6834 8.09763 14.3166 7.70711 14.7071L4.41421 18L7.70711 21.2929C8.09763 21.6834 8.09763 22.3166 7.70711 22.7071C7.31658 23.0976 6.68342 23.0976 6.29289 22.7071L2.29289 18.7071C1.90237 18.3166 1.90237 17.6834 2.29289 17.2929L6.29289 13.2929C6.68342 12.9024 7.31658 12.9024 7.70711 13.2929Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 12C21.5523 12 22 12.4477 22 13V14C22 15.3261 21.4732 16.5979 20.5355 17.5355C19.5979 18.4732 18.3261 19 17 19H3C2.44772 19 2 18.5523 2 18C2 17.4477 2.44772 17 3 17H17C17.7956 17 18.5587 16.6839 19.1213 16.1213C19.6839 15.5587 20 14.7956 20 14V13C20 12.4477 20.4477 12 21 12Z"
          fill="#112645"
        />
      </g>
    </svg>
  );
}
