import { Grid } from '@mui/material';

import BenefitsBottomsheet from 'src/components/benefits-bottomsheet/benefits-bottomsheet';

import useDefaultLayout from './default-layout.hook';
import SliderMenuComponent from './components/slider-menu/slider-menu';
import BottomNavigationComponent from './components/bottom-navigation/bottom-navigation';

type Props = {
  children: React.ReactNode;
};

export default function DefaultLayout({ children }: Props) {
  const {
    pathname,
    bottomNavHeight,
    bottomNavRef,
    sliderOpen,
    theme,
    TABS,
    selectedTab,
    menuItems,
    sliderMenuRef,
    isBenefitsBottomsheetOpen,
    setIsBenefitsBottomsheetOpen,
    onClickBottomNavigation,
    handleChangeTab,
  } = useDefaultLayout();

  return (
    <Grid container justifyContent="center" sx={{ height: '100%', position: 'relative' }}>
      <Grid item xs={12} md={4} sx={{ pb: `${bottomNavHeight}px`, overflowY: 'auto' }}>
        {children}
      </Grid>

      <SliderMenuComponent
        onClickBottomNavigation={onClickBottomNavigation}
        theme={theme}
        sliderOpen={sliderOpen}
        selectedTab={selectedTab}
        bottomNavHeight={bottomNavHeight}
        TABS={TABS}
        menuItems={menuItems}
        sliderMenuRef={sliderMenuRef}
        handleChangeTab={handleChangeTab}
      />

      {(!pathname.includes('test') && !pathname.includes('auth')) &&
        <BottomNavigationComponent
          pathname={pathname}
          sliderOpen={sliderOpen}
          onClickBottomNavigation={onClickBottomNavigation}
          bottomNavRef={bottomNavRef}
          theme={theme}
        />
      }

      <BenefitsBottomsheet
        isOpen={isBenefitsBottomsheetOpen}
        onClose={() => setIsBenefitsBottomsheetOpen(false)}
      />
    </Grid>
  );
}
