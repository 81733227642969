import { Theme } from '@mui/material/styles';
import { ChipProps, chipClasses } from '@mui/material/Chip';

declare module '@mui/material/Chip' {
  interface ChipOwnProps {
    selected?: boolean
  }
}

export function chip(theme: Theme) {

  const rootStyles = (ownerState: ChipProps) => {
    const primaryColor = ownerState.color === 'primary';

    const secondaryColor = ownerState.color === 'secondary';

    const filledVariant = ownerState.variant === 'filled';

    const { selected } = ownerState;

    const defaultStyle = {
      ...(primaryColor && {
        // FILLED
        ...(filledVariant && {
          color: selected ? theme.palette.common.white : theme.palette.blueBrand[400],
          backgroundColor: selected ? theme.palette.blueBrand[400] : theme.palette.common.white,
          boxShadow:  `0px 4px 8px 0px #47474766`,
          
          '&:hover': {
            color: theme.palette.blueBrand[400],
            backgroundColor: theme.palette.blueBrand[0],
          },
        }),
      }),
      ...(secondaryColor && {
        // FILLED
        ...(filledVariant && {
          color: theme.palette.blueBrand[400],
          backgroundColor: selected ? theme.palette.yellowBrand[0] : theme.palette.common.white,
          boxShadow:  `0px 4px 8px 0px #47474766`,
          '&:hover': {
            color: theme.palette.blueBrand[400],
            backgroundColor: theme.palette.yellowBrand[100],
          },
        }),
      }),
    };

    const disabledState = {
      [`&.${chipClasses.disabled}`]: {
        opacity: 1,
        color: theme.palette.grey[500],
        backgroundColor: theme.palette.grey[200],
      },
    };

    return [
      defaultStyle,
      disabledState,
      {
        ...theme.typography.inputS700,
        borderRadius: 100,
        padding: theme.spacing(1, 2, 1, 2)
      },
    ];
  };

  return {
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ChipProps }) => rootStyles(ownerState),
      },
    },
  };
}
