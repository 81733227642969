import { alpha, Theme } from '@mui/material/styles';
import { ButtonProps, buttonClasses } from '@mui/material/Button';

// NEW VARIANT
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    micro: true;
  }
}

export function button(theme: Theme) {

  const rootStyles = (ownerState: ButtonProps) => {
    const primaryColor = ownerState.color === 'primary';

    const secondaryColor = ownerState.color === 'secondary';

    const containedVariant = ownerState.variant === 'contained';

    const outlinedVariant = ownerState.variant === 'outlined';

    const textVariant = ownerState.variant === 'text';

    const microVariant = ownerState.variant === 'micro'

    const defaultStyle = {
      boxShadow: 'none',
      borderRadius: 2,
      ...(primaryColor && {
        // CONTAINED
        ...(containedVariant && {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.blueBrand[400],
          '&:hover': {
            color: theme.palette.blueBrand[400],
            backgroundColor: alpha(theme.palette.blueBrand[400], 0.2),
          },
          [`&.${buttonClasses.disabled}`]: {
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.grey[200],
          }
        }),
        // OUTLINED
        ...(outlinedVariant && {
          color: theme.palette.blueBrand[400],
          borderColor: theme.palette.blueBrand[400],
          '&:hover': {
            backgroundColor: alpha(theme.palette.blueBrand[400], 0.2),
          },
          [`&.${buttonClasses.disabled}`]: {
            color: theme.palette.grey[500],
            borderColor: theme.palette.grey[200],
          }
        }),
        // TEXT
        ...(textVariant && {
          color: theme.palette.blueBrand[400],
          '&:hover': {
            backgroundColor: alpha(theme.palette.blueBrand[400], 0.2),
          },
          [`&.${buttonClasses.disabled}`]: {
            color: theme.palette.grey[500],
          }
        }),
        // MICRO
        ...(microVariant && {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.blueBrand[400],
          '&:hover': {
            color: theme.palette.blueBrand[400],
            backgroundColor: alpha(theme.palette.blueBrand[400], 0.2),
          },
          [`&.${buttonClasses.disabled}`]: {
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.grey[200],
          }
        }),
      }),
      ...(secondaryColor && {
        // CONTAINED
        ...(containedVariant && {
          color: theme.palette.blueBrand[400],
          backgroundColor: theme.palette.yellowBrand[0],
          '&:hover': {
            color: theme.palette.blueBrand[400],
            backgroundColor: theme.palette.yellowBrand[100],
          },
          [`&.${buttonClasses.disabled}`]: {
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.grey[200],
          }
        }),
        // OUTLINED
        ...(outlinedVariant && {
          color: theme.palette.yellowBrand[0],
          borderColor: theme.palette.yellowBrand[0],
          '&:hover': {
            color: theme.palette.blueBrand[400],
            backgroundColor: alpha(theme.palette.yellowBrand[100], 0.4),
            borderColor: theme.palette.blueBrand[400],
          },
          [`&.${buttonClasses.disabled}`]: {
            color: theme.palette.grey[200],
            borderColor: theme.palette.grey[200],
          }
        }),
        // TEXT
        ...(textVariant && {
          color: theme.palette.yellowBrand[0],
          '&:hover': {
            color: theme.palette.blueBrand[400],
            backgroundColor: theme.palette.yellowBrand[0],
          },
          [`&.${buttonClasses.disabled}`]: {
            color: theme.palette.grey[200],
          }
        }),
        // MICRO
        ...(microVariant && {
          color: theme.palette.blueBrand[400],
          backgroundColor: theme.palette.yellowBrand[0],
          '&:hover': {
            color: theme.palette.blueBrand[400],
            backgroundColor: theme.palette.yellowBrand[100],
          },
          [`&.${buttonClasses.disabled}`]: {
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.grey[200],
          }
        }),
      })
    };

    const size = {
      height: microVariant ? 33 : 49,
      padding: microVariant ? 8 : 16,
      ...(microVariant ? {
        maxWidth: 'fit-content'
      } : {
        width: '100%'
      })
    };

    return [defaultStyle, size];
  };

  return {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ButtonProps }) => rootStyles(ownerState),
      },
    },
  };
}
