import { Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';

import HomeLayoutIcon from 'src/assets/icons/home-layout-icon';
import GiftLayoutIcon from 'src/assets/icons/gift-layout-icon';
import GroupLayoutIcon from 'src/assets/icons/group-layout-icon';
import SearchLayoutIcon from 'src/assets/icons/serch-layout-icon';
import InvoiceLayoutIcon from 'src/assets/icons/invoice-layout-icon';

export type Props = {
  pathname: string;
  sliderOpen: boolean;
  onClickBottomNavigation: (newValue: number) => void;
  bottomNavRef: React.RefObject<HTMLDivElement>;
  theme: any;
};

export default function BottomNavigationComponent({ pathname, sliderOpen, onClickBottomNavigation, bottomNavRef, theme }: Props) {
  return (
    <Paper
      ref={bottomNavRef}
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      elevation={3}
      data-testid="bottom-navigation-container"
    >
      <BottomNavigation
        onChange={(e, newValue) =>{e.stopPropagation(); onClickBottomNavigation(newValue)}}
        showLabels
        sx={{
          height: 80,
          backgroundColor:
            sliderOpen ? theme.palette.blueBrand[500] : theme.palette.yellowBrand[100],
          '& .MuiBottomNavigationAction-root': {
            minWidth: 0,
            padding: '0 12px',
          },
          '@media (max-width:375px)': {
            '& .MuiBottomNavigationAction-root': {
              padding: '0 6px',
            },
          },
        }}
        data-testid="bottom-navigation"
      >
        <BottomNavigationAction
          icon={<HomeLayoutIcon selected={pathname.includes('home') && !sliderOpen} highlighted={sliderOpen} />}
          data-testid="bottom-nav-home"
        />
        <BottomNavigationAction
          icon={<SearchLayoutIcon selected={pathname.includes('search') && !sliderOpen} highlighted={sliderOpen} />}
          data-testid="bottom-nav-search"
        />
        <BottomNavigationAction
          icon={
            <InvoiceLayoutIcon
              selected={pathname.includes('invoice') && !sliderOpen}
              none={!sliderOpen}
              highlighted={sliderOpen}
            />
          }
          data-testid="bottom-nav-invoice"
        />
        <BottomNavigationAction
          icon={<GiftLayoutIcon selected={pathname.includes('benefits') && !sliderOpen} highlighted={sliderOpen} />}
          data-testid="bottom-nav-gift"
        />
        <BottomNavigationAction
          icon={<GroupLayoutIcon selected={sliderOpen} />}
          data-testid="bottom-nav-group"
        />
      </BottomNavigation>
    </Paper>
  );
}
