type ChevronCNProps = {
    fill?: string;
    orientation?: string;
};

export default function Chevron({ fill, orientation }: ChevronCNProps) {
    if (!orientation) {
        orientation = 'right'
    }
    let retorno
    switch (orientation) {
        case 'right':
            retorno = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 1.98633L7.97317 1.98655L14 7.98607L8.02682 7.98585L2 1.98633Z" fill={fill || '#214983'}/>
            <path d="M2 13.9863H7.97308L14 7.98607L8.02682 7.98585L2 13.9863Z" fill={fill || '#214983'}/>
            </svg>            
            break;
        case 'left':
            retorno = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 14.1L8.02682 14.0998L2 8.10023L7.97317 8.10045L14 14.1Z" fill={fill || '#214983'} />
                <path d="M14 2.09998H8.02691L2 8.10023L7.97317 8.10045L14 2.09998Z" fill={fill || '#214983'} />
            </svg>
            break;
        case 'up':
            retorno = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 14.1L2.00022 8.1268L7.99974 2.09998L7.99952 8.07315L2 14.1Z" fill={fill || '#214983'} />
                <path d="M14 14.1V8.12689L7.99974 2.09998L7.99952 8.07315L14 14.1Z" fill={fill || '#214983'} />
            </svg>
            break;
        case 'down':
        default:
            retorno = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 2.09998L13.9998 8.07314L8.00026 14.1L8.00048 8.1268L14 2.09998Z" fill={fill || "#214983"} />
                <path d="M2 2.09998V8.07306L8.00026 14.1L8.00048 8.1268L2 2.09998Z" fill={fill || "#214983"} />
            </svg>
            break;
    }

    return retorno
}