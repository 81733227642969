import { useState, useEffect } from 'react';
import { OrbitProgress } from 'react-loading-indicators'

import { useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

export default function SplashScreen({ sx, ...other }: BoxProps) {
  const theme = useTheme()
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <Box
      sx={{
        right: 0,
        width: 1,
        bottom: 0,
        height: 1,
        zIndex: 500,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        ...sx,
      }}
      {...other}
    >
      <OrbitProgress variant="dotted" color={theme.palette.blueBrand[400]} size="medium" text="" textColor="" />
    </Box>
  );
}
