import axios, { AxiosRequestConfig } from 'axios';

import { API_URL } from 'src/config-global';
import { AxiosHttpClient } from 'src/http/axios.http';

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json';

    return { ...config };
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const api = new AxiosHttpClient(API_URL);

export const endpoints = {
  auth: {
    login: 'auth/login',
    userData: 'auth/user-data',
    newToken: 'auth/new-token',
    termsCheck: 'auth/terms/check',
    termsAccept: 'auth/terms/accept',
    deleteAccount: 'auth/delete-account'
  },
  wallet: {
    myBalance: 'wallet/my-balance'
  },
  suggestion: {
    sendSuggestion: 'suggestion/create',
  },
  benefits: {
    experience: {
      list: 'benefits/experience/list',
      highlight: 'benefits/experience/highlight',
      details: (id: string) => `benefits/experience/details/${id}`,
      rescue: 'benefits/experience/rescue',
      rescueDetails: (id: string) => `benefits/experience/rescue-details/${id}`,
    },
    product: {
      list: 'benefits/product/list',
      highlight: 'benefits/product/highlight',
      details: (id: string) => `benefits/product/details/${id}`,
      rescue: 'benefits/product/rescue',
      rescueDetails: (id: string) => `benefits/product/rescue-details/${id}`,
      allFilter: 'benefits/product/all',
    },
    coupons: {
      highlightCoupons: 'benefits/coupons/highlight',
      benefitsCoupons: 'benefits/coupons/benefits',
      discounts: 'benefits/coupons/discounts',
      coupomDetails: (id: string) => `benefits/coupons/benefits-details/${id}`,
      discountDetails: (id: string) => `benefits/coupons/discounts-details/${id}`,
      rescueCoupon: 'benefits/coupons/couponRescue',
      rescueBenefit: 'benefits/coupons/benefitRescue',
      couponRescueDetails: (id: string) => `benefits/coupon/coupon-rescue-details/${id}`,
      benefitRescueDetails: (id: string) => `benefits/coupon/benefits-rescue-details/${id}`,
    },
    events: {
      list: 'benefits/event/list',
      lastDays: 'benefits/event/last-days',
      my: 'benefits/event/my',
      details: (id: string) => `benefits/event/details/${id}`,
      scheduledDetails: (id: string) => `benefits/event/scheduled-details/${id}`,
      scheduleDetails: (id: string) => `benefits/event/schedule-details/${id}`,
      favorite: `benefits/event/favorite`,
      cancelSchedule: `benefits/event/cancel-schedule`,
      schedule: `benefits/event/schedule`,
    }
  },
  profile: {
    myExchangesList: 'profile/myExchanges'
  },
  test: {
    generatePoints: 'auth/add-points-test',
    spentPoints: 'auth/remove-points-test',
    getExtract: 'wallet/my-records',
  }
};
