import React from 'react';
import { closeSnackbar, MaterialDesignContent } from 'notistack';

import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledNotistack = styled(MaterialDesignContent)(({ theme }) => (
  {
    '& #notistack-snackbar': {
      flexGrow: 1,
      gap: 16,
    },
    '&.notistack-MuiContent': {
      color: theme.palette.text.primary,
      boxShadow: theme.customShadows.z8,
      padding: 16,
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    '&.notistack-MuiContent-default': {
      ...theme.typography.inputM500,
      padding: theme.spacing(4, 8, 4, 8),
      color: theme.palette.common.white,
      backgroundColor: theme.palette.blueBrand[400],
    },
    // '&.notistack-MuiContent-info': {},
    '&.notistack-MuiContent-success': {
      ...theme.typography.h6,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.blueBrand[400],
      borderRadius: 0
    },
    '&.notistack-MuiContent-warning': {
      ...theme.typography.h6,
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.yellowBrand[0],
      borderRadius: 0
    },
    '&.notistack-MuiContent-error': {
      ...theme.typography.h6,
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.redBrand[100],
      borderRadius: 0
    },
    '&.notistack-MuiContent-success2': {
      ...theme.typography.h6,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.blueBrand[400],
      borderRadius: 0
    },
    '&.notistack-MuiContent-error2': {
      ...theme.typography.h6,
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.grey[200],
      borderRadius: 0
    },
}));

export const StyledBottomSheet = React.forwardRef((props: any, ref) => 
    <Box 
      ref={ref}
      sx={{
        padding: (theme) => theme.spacing(4, 8, 4, 8),
        backgroundColor: (theme) => theme.palette.blueBrand[400],
        borderRadius: 1,
        margin: 0,
        bottom: -14,
        zIndex: 9999,
      }}
    >
      <Stack alignItems='center' spacing={4} onTouchEnd={() => closeSnackbar(props.id)} >
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            width: '15%',
            height: 4,
            borderRadius: 50
          }}
        />
        <Typography variant='inputM500' color='common.white'>
          {props.message}
        </Typography>
      </Stack>
    </Box>
)



// ----------------------------------------------------------------------

type StyledIconProps = {
  color: 'info' | 'success' | 'warning' | 'error';
};

export const StyledIcon = styled('span')<StyledIconProps>(({ color, theme }) => ({
  width: 44,
  height: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1.5),
  color: theme.palette[color].main,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette[color].main, 0.16),
}));
