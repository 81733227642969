import { Theme, alpha } from '@mui/material/styles';
import { LinearProgressProps, linearProgressClasses } from '@mui/material/LinearProgress';

export function progress(theme: Theme) {
  const rootStyles = (ownerState: LinearProgressProps) => {
    const bufferVariant = ownerState.variant === 'buffer';

    const defaultStyle = {
      borderRadius: 1,
      height: 4,
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 1,
        ...(ownerState.color === 'primary' && {
          backgroundColor: theme.palette.blueBrand[400],
        }),
        ...(ownerState.color === 'secondary' && {
          backgroundColor: theme.palette.yellowBrand[100],
        }),
      },
      ...(bufferVariant && {
        backgroundColor: 'transparent',
      }),
      ...(ownerState.color === 'primary' && {
        backgroundColor: alpha(theme.palette.blueBrand[400], 0.24),
      }),
      ...(ownerState.color === 'secondary' && {
        backgroundColor: theme.palette.yellowBrand[0],
      }),
    };

    return [defaultStyle];
  };

  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: LinearProgressProps }) => rootStyles(ownerState),
      },
    },
  };
}
