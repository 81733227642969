import React from 'react';
import { useNavigate } from 'react-router';

import { TransitionProps } from '@mui/material/transitions';
import { Box, Grid, Stack, Slide, Dialog, Button, useTheme } from '@mui/material';

import { paths } from 'src/routes/paths';

import HomeCard from '../home-card/home-card';
import { useBenefitsBottomsheet } from './benefits-bottomsheet.hook';


export interface BenefitsBottomsheetProps{
  isOpen: boolean;
  onClose: () => void;
}

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

export default function BenefitsBottomsheet({
  isOpen,
  onClose
}: BenefitsBottomsheetProps){
  const { OPTIONS } = useBenefitsBottomsheet();
  const theme = useTheme()
  const navigate = useNavigate()
  
  return (
    <Dialog  
      data-testid='benefitsBottomsheet-render-test'
      open={isOpen}
      onClose={onClose}
      fullWidth
      TransitionComponent={Transition}
      sx={{ 
        '.MuiDialog-paper': { 
          bottom: 0, 
          position: 'absolute', 
          mb: 0,
          width: '100%'
        }, 
        '.MuiPaper-rounded': {
          borderTopLeftRadius: '8px !important',
          borderTopRightRadius: '8px !important',
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
        } 
      }}
    > 
      <Stack 
        p={8} 
        spacing={12} 
        alignItems='center' 
      >
        <Box
          height={4}
          width='15%'
          bgcolor={theme.palette.grey[500]}
          borderRadius={50}
          onClick={() => onClose()}
        />
        <Box>
          <Grid container spacing={8}>
            {OPTIONS.map((opt, index) => (
              <Grid item xs={4} key={`BENEFIT_OPTION_${index}`}>
                <HomeCard
                  option={opt}
                  onClick={() => 
                    {
                      onClose(); 
                      setTimeout(() => {
                        navigate(opt.path);
                      }, 100)
                    }
                  }
                  bgColor={theme.palette.blueBrand[0]}
                  textColor={theme.palette.blueBrand[400]}
                  width='100%'
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Button 
          variant='micro' 
          color='primary' 
          onClick={() => {
            onClose(); 
            setTimeout(() => {
              navigate(paths.myExchanges.root)
            }, 100)
          }}>
          Ver minhas trocas
        </Button>
      </Stack>
    </Dialog>
  )
}
