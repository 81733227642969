const ROOTS = {
  AUTH: '/auth',
  EXPERIENCES: '/benefits/experiences',
  PRODUCTS: '/benefits/products',
  COUPONS: '/benefits/coupons',
  BENEFITS: '/benefits/allBenefitsCoupons',
  DISCOUNTS: '/benefits/allDiscountsCoupons',
  EVENTS: '/benefits/events'
};

export const paths = {
  root: '/',
  auth: {
    root: ROOTS.AUTH,
    login: `${ROOTS.AUTH}/login`,
  },

  // PAGES
  events:{
    root: ROOTS.EVENTS,
    my: `${ROOTS.EVENTS}/my`,
    about: (id: string) => `${ROOTS.EVENTS}/about/${id}`,
    schedule: (id: string) => `${ROOTS.EVENTS}/schedule/${id}`,
    scheduled: (id: string) => `${ROOTS.EVENTS}/scheduled/${id}`,
  },
  
  myProfile:{
    root: '/user/myProfile',
  },
      
  myLevel:{
    root: '/user/myLevel',
  },
  
  deleteAccount:{
    root: '/user/deleteAccount',
  },
      
  registrationData:{
    root: '/user/registrationData',
  },
  
  homolMotor:{
    root: '/test/homolMotor',
  },
      
  myExchanges: {
    root: '/user/myExchanges',
  },

  products: {
    root: ROOTS.PRODUCTS,
    all: `${ROOTS.PRODUCTS}/all`,
    details: (id: string) => `${ROOTS.PRODUCTS}/details/${id}`,
    rescue: (id: string) => `${ROOTS.PRODUCTS}/rescue/${id}`,
  },

  coupons: {
    root: ROOTS.COUPONS,
    benefits: ROOTS.BENEFITS,
    discounts: ROOTS.DISCOUNTS,
    couponDetails: (id: string) => `${ROOTS.COUPONS}/details/${id}`,
    couponRescue: (id: string) => `${ROOTS.COUPONS}/couponRescue/${id}`,
    benefitsDetails: (id: string) => `${ROOTS.COUPONS}/benefitsDetails/${id}`,
    benefitsRescue: (id: string) => `${ROOTS.COUPONS}/benefitsRescue/${id}`,
  },

  experiences: {
    root: ROOTS.EXPERIENCES,
    details: (id: string) => `${ROOTS.EXPERIENCES}/details/${id}`,
    rescue: (id: string) => `${ROOTS.EXPERIENCES}/rescue/${id}`,
  },

  home: {
    root: '/home',
  },

  test: {
    root: '/test/test',
  },

  authError: {
    root: '/auth/authError',
  },

  register: {
    root: '/auth/register',
  },

  example: {
    root: '/example',
  },

  testAuthAndGuard: {
    root: '/testAuthAndGuard',
  },

  testRepository: {
    root: '/testRepository',
  },

  error: {
    notFound: '/404',
    forbidden: '/403',
    serverError: '/500',
  },
};
