type Props = {
  fill?: string;
};

export default function Help({ fill }: Props) {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.75 0.890015H5.75C2.58 0.890015 0 3.47001 0 6.64001V14.64C0 17.81 2.58 20.39 5.75 20.39H13.75C16.92 20.39 19.5 17.81 19.5 14.64V6.64001C19.5 3.47001 16.92 0.890015 13.75 0.890015ZM10.5 14.74C10.5 15.15 10.16 15.49 9.75 15.49C9.34 15.49 9 15.15 9 14.74V14.64C9 14.23 9.34 13.89 9.75 13.89C10.16 13.89 10.5 14.23 10.5 14.64V14.74ZM10.5 11.29V12.14C10.5 12.55 10.16 12.89 9.75 12.89C9.33 12.89 9 12.55 9 12.14V10.64C9 10.23 9.34 9.89001 9.75 9.89001C10.44 9.89001 11 9.33001 11 8.64001C11 7.95001 10.44 7.39001 9.75 7.39001C9.06 7.39001 8.5 7.95001 8.5 8.64001C8.5 9.05001 8.16 9.39001 7.75 9.39001C7.34 9.39001 7 9.05001 7 8.64001C7 7.12001 8.23 5.89001 9.75 5.89001C11.27 5.89001 12.5 7.12001 12.5 8.64001C12.5 9.90001 11.65 10.96 10.5 11.29Z"
        fill={fill || 'white'}
      />
    </svg>
  );
}
