import { paths } from 'src/routes/paths';

// export const { BASE_URL } = import.meta.env.VITE_BASE_URL;
// export const { API_URL } = import.meta.env.VITE_API_URL;
// export const { SENTRY_DSN } = import.meta.env.VITE_SENTRY_DSN;
// export const { ASSETS_API_URL } = import.meta.env.VITE_ASSETS_API_URL;
export const BASE_URL = "http://localhost:3000";
export const API_URL = "https://test.cn.backend.roxcode.io";
export const SENTRY_DSN = "https://635327bb76b5c57edf9ff06323fb2a52@o4506650949189632.ingest.us.sentry.io/4507420158853120";
export const ASSETS_API_URL = "https://i.pravatar.cc/150?img=";

export const PATH_AFTER_LOGIN = paths.root;
