import { useLocation, useNavigate } from 'react-router';
import { useRef, useState, useEffect, useCallback } from 'react';

import { useTheme } from '@mui/material';

import { paths } from 'src/routes/paths';

export default function useDefaultLayout() {
  const [ value, setValue ] = useState(-1);
  const bottomNavRef = useRef<HTMLDivElement | null>(null);
  const [ bottomNavHeight, setBottomNavHeight ] = useState(0);
  const theme = useTheme();
  const { pathname } = useLocation()
  const [ isBenefitsBottomsheetOpen, setIsBenefitsBottomsheetOpen ] = useState(false);
  const [ isBenefitsOpen, setIsBenefitsOpen ] = useState(false);
  const [ isAccumulatePoints, setIsAccumulatePoints ] = useState(false);
  const [ sliderOpen, setSliderOpen ] = useState(false);
  const [ selectedTab, setSelectedTab ] = useState('benefits');
  const navigate = useNavigate()
  const TABS = [
    { title: 'Benefícios', tab: 'benefits' },
    { title: 'Meu Viva', tab: 'my-viva' },
  ];

  const toggleBenefitsMenu = () => {
    setIsBenefitsOpen((prev) => !prev);
  };

  const toggleAccumulatePointsMenu = () => {
    setIsAccumulatePoints((prev) => !prev);
  };

  const onClickItem = (goTo: string) => {
    setSliderOpen(false)
    document.body.style.overflow = 'auto';
    setTimeout(() => {
      navigate(goTo)
    },100)
  }

  const menuItems = [
    { label: 'ENVIAR NOTAS', hasChevron: false },
    { label: 'EVENTOS', hasChevron: false },
    { label: 'PROMOÇÕES', hasChevron: false },
    {
      label: 'BENEFÍCIOS',
      hasChevron: true,
      isOpen: isBenefitsOpen,
      onClick: toggleBenefitsMenu,
      onClickSubItem: onClickItem,
      subItems: [
        {
          label: 'Colecionáveis',
          path: paths.home.root,
        },
        {
          label: 'Experiências',
          path: paths.experiences.root,
        },
        {
          label: 'Produtos',
          path: paths.products.root,
        },
        {
          label: 'Promoções',
          path: paths.home.root,
        },
        {
          label: 'Cupons',
          path: paths.coupons.root,
        },
      ],
    },
    {
      label: 'ACUMULAR PONTOS',
      hasChevron: true,
      isOpen: isAccumulatePoints,
      onClick: toggleAccumulatePointsMenu,
      onClickSubItem: onClickItem,
      subItems: [
        {
          label: 'Enviar notas',
          path: paths.home.root,
        },
        {
          label: 'Missões',
          path: paths.home.root,
        },
        {
          label: 'Check-in',
          path: paths.home.root,
        }, 
        {
          label: 'Jogos',
          path: paths.home.root,
        },
      ],
      breakLine: true,
    },
  ];

  const handleChangeTab = useCallback((tab: string) => {
    setSelectedTab(tab);
  }, []);

  useEffect(() => {
    if (bottomNavRef.current) {
      setBottomNavHeight(bottomNavRef.current.clientHeight);
    }
  }, [bottomNavRef.current]);

  const onClickBottomNavigation = (newValue: number) => {
    if(newValue === 4){
      setSliderOpen(!sliderOpen);
      if (!sliderOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }else if(newValue === 3){
      setIsBenefitsBottomsheetOpen(true)
    }else if(newValue === 0){
      navigate(paths.home.root)
    } else if(newValue === 1) {
      window.open(`superapp://viva/prizor/campanhas/"Clube Viva"/2024-08-29T11:26:44.297/${encodeURIComponent('https://test.viva.compass.scn.roxcode.io/test/test')}`,'_self')
    }
  }

  const sliderMenuRef = useRef<HTMLDivElement | null>(null);

  return {
    bottomNavHeight,
    bottomNavRef,
    sliderOpen,
    theme,
    value,
    pathname,
    TABS,
    selectedTab,
    isBenefitsOpen,
    isAccumulatePoints,
    menuItems,
    sliderMenuRef,
    isBenefitsBottomsheetOpen,
    setIsBenefitsBottomsheetOpen,
    onClickBottomNavigation,
    setSliderOpen,
    toggleAccumulatePointsMenu,
    setValue,
    handleChangeTab,
    toggleBenefitsMenu,
  };
}
