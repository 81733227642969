import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type States = {
  accessToken: string | undefined;
  refreshToken: string | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  userRole: string;
};

type Actions = {
  setAccessToken: (accessToken: string | undefined) => void;
  setRefreshToken: (refreshToken: string | undefined) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setSession: (accessTokenParam: string, refreshTokenParam: string) => void;
  clearSession: () => void;
  setIsLoading: (loadingState: boolean) => void;
  setUserRole: (role: string) => void;
};

type AuthStore = States & Actions;

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      accessToken: undefined,
      refreshToken: undefined,
      isAuthenticated: false,
      isLoading: false,
      userRole: '',
      setAccessToken: (accessToken: string | undefined) => set({ accessToken }),
      setRefreshToken: (refreshToken: string | undefined) => set({ refreshToken }),
      setIsAuthenticated: (isAuthenticated: boolean) => set({ isAuthenticated }),
      setIsLoading: (loadingState: boolean) => set({ isLoading: loadingState }),
      clearSession: () =>
        set({ accessToken: undefined, refreshToken: undefined, isAuthenticated: false }),
      setSession: (accessTokenParam: string, refreshTokenParam: string) => {
        if (accessTokenParam && refreshTokenParam) {
          set({ accessToken: accessTokenParam, refreshToken: refreshTokenParam });
          set({ isAuthenticated: true });
        }
      },
      setUserRole: (role: string) => set({ userRole: role }),
    }),
    {
      name: 'authentication-store',
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        isAuthenticated: state.isAuthenticated,
      }),
    }
  )
);
