import React from 'react';

import { Box, Typography } from '@mui/material';

import Image from 'src/components/image';

export interface HomeCardProps {
  onClick?: (data: any) => void;
  option: {
    imgPath: string;
    title: string;
  };
  bgColor?: any;
  textColor?: any;
  height?: string;
  width?: string;
}

export default function HomeCard({ onClick, option, bgColor, textColor, height, width }: HomeCardProps) {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      sx={{
        borderRadius: 4,
        height: height ?? 120,
        width: width ?? 100,
        bgcolor: bgColor,
      }}
      onClick={onClick}
    >
      <Image
        src={`${option.imgPath}`}
        sx={{ height: '80%', width: width ?? 100, borderRadius: '4px 4px 0px 0px' }}
      />
      <Typography variant="paragraphP700" p={4} color={textColor}>
        {option.title}
      </Typography>
    </Box>
  );
}
