type IconProps = {
  selected?: boolean;
  highlighted?: boolean;
  none?: boolean;
};

export default function InvoiceLayoutIcon({ selected = false, highlighted, none }: IconProps) {
  if (none) {
    return (
      <svg
        width="57"
        height="58"
        viewBox="0 0 57 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="57" height="57" rx="28.5" fill="white" fillOpacity="0.4" />
        <path
          d="M41.0939 16.9972C40.8364 16.8387 40.5194 16.8288 40.252 16.9576L38.9049 17.6311L37.5579 16.9576C37.3202 16.8387 37.023 16.8387 36.7853 16.9576L35.4284 17.6311L34.0813 16.9576C33.8337 16.8387 33.5465 16.8387 33.3088 16.9576L31.9815 17.6311L30.6543 16.9576C30.4166 16.8387 30.1194 16.8387 29.8817 16.9576L28.5149 17.6311L27.1678 16.9576C26.9202 16.8387 26.633 16.8387 26.3952 16.9576L25.0482 17.6311L23.7011 16.9576C23.4337 16.8189 23.1168 16.8387 22.8592 16.9972C22.6017 17.1557 22.4531 17.433 22.4531 17.7302V32.4685H17.2432C17.0154 32.4685 16.7777 32.5081 16.5697 32.5972C16.3617 32.6864 16.1636 32.8052 16.0051 32.9736C15.8368 33.1321 15.7179 33.3302 15.6288 33.5481C15.5396 33.7561 15.5 33.974 15.5 34.2018C15.5 36.9652 15.5 41.1351 20.7 41.1351H36.3C37.6867 41.1351 38.9941 40.5904 39.9747 39.6098C40.9552 38.6292 41.5 37.3218 41.5 35.9351V17.7401C41.5 17.4429 41.3415 17.1656 41.084 17.0071L41.0939 16.9972ZM32.4272 39.4018H20.7C19.5015 39.4018 18.7091 39.1641 18.1941 38.649C17.4017 37.8566 17.2432 36.3709 17.2432 34.2216L17.273 34.2018H31.1V35.9351C31.1 37.2029 31.5754 38.4509 32.4272 39.4018ZM31.9716 32.4685H24.1667V19.1366L24.6421 19.3744C24.8798 19.4932 25.177 19.4932 25.4147 19.3744L26.7617 18.7008L28.1088 19.3744C28.3465 19.4932 28.6436 19.4932 28.8813 19.3744L30.2482 18.7008L31.5754 19.3744C31.823 19.4932 32.1103 19.4932 32.3579 19.3744L33.695 18.7008L35.0322 19.3744C35.2699 19.4932 35.567 19.4932 35.8048 19.3744L37.1716 18.7008L38.5187 19.3744C38.7663 19.4932 39.0535 19.4932 39.2912 19.3744L39.7667 19.1366V35.9351C39.7667 36.8464 39.4002 37.7378 38.7465 38.3816C37.439 39.689 35.151 39.689 33.8436 38.3816C33.1998 37.7378 32.8333 36.8464 32.8333 35.9351V33.3401C32.8333 32.8646 32.447 32.4685 31.9617 32.4685H31.9716Z"
          fill="#112645"
        />
        <path
          d="M38.0333 22.9401C38.0333 22.4646 37.647 22.0685 37.1617 22.0685H26.7617C26.2863 22.0685 25.8901 22.4547 25.8901 22.9401C25.8901 23.4254 26.2764 23.8117 26.7617 23.8117H37.1617C37.6371 23.8117 38.0333 23.4254 38.0333 22.9401Z"
          fill="#112645"
        />
        <path
          d="M38.0333 27.2685C38.0333 26.793 37.647 26.3968 37.1617 26.3968H30.2284C29.753 26.3968 29.3568 26.7831 29.3568 27.2685C29.3568 27.7538 29.743 28.1401 30.2284 28.1401H37.1617C37.6371 28.1401 38.0333 27.7538 38.0333 27.2685Z"
          fill="#112645"
        />
      </svg>
    );
  }
  if (selected) {
    return (
      <svg
        width="57"
        height="58"
        viewBox="0 0 57 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="57" height="57" rx="28.5" fill="white" fillOpacity="0.4" />
        <g clipPath="url(#clip0_8217_5650)">
          <path
            d="M41.0939 16.9957C40.8364 16.8372 40.5194 16.8273 40.252 16.9561L38.905 17.6296L37.5579 16.9561C37.3202 16.8372 37.023 16.8372 36.7853 16.9561L35.4284 17.6296L34.0813 16.9561C33.8337 16.8372 33.5465 16.8372 33.3088 16.9561L31.9815 17.6296L30.6543 16.9561C30.4166 16.8372 30.1194 16.8372 29.8817 16.9561L28.5149 17.6296L27.1678 16.9561C26.9202 16.8372 26.633 16.8372 26.3952 16.9561L25.0482 17.6296L23.7011 16.9561C23.4337 16.8174 23.1168 16.8372 22.8592 16.9957C22.6017 17.1542 22.4531 17.4315 22.4531 17.7286V32.4669H17.2432C17.0154 32.4669 16.7777 32.5065 16.5697 32.5957C16.3617 32.6848 16.1636 32.8037 16.0051 32.9721C15.8368 33.1305 15.7179 33.3286 15.6288 33.5465C15.5396 33.7545 15.5 33.9725 15.5 34.2003C15.5 36.9637 15.5 41.1336 20.7 41.1336H36.3C37.6867 41.1336 38.9941 40.5888 39.9747 39.6083C40.9552 38.6277 41.5 37.3203 41.5 35.9336V17.7385C41.5 17.4414 41.3415 17.1641 41.084 17.0056L41.0939 16.9957ZM20.7 39.4003C19.5015 39.4003 18.7091 39.1625 18.1941 38.6475C17.4017 37.8551 17.2432 36.3694 17.2432 34.2201L17.273 34.2003H31.1V35.9336C31.1 37.2014 31.5754 38.4494 32.4272 39.4003H20.7ZM37.1716 28.1385H30.2383C29.7629 28.1385 29.3667 27.7523 29.3667 27.2669C29.3667 26.7816 29.753 26.3953 30.2383 26.3953H37.1716C37.647 26.3953 38.0432 26.7816 38.0432 27.2669C38.0432 27.7523 37.6569 28.1385 37.1716 28.1385ZM37.1716 23.8003H26.7716C26.2962 23.8003 25.9 23.414 25.9 22.9286C25.9 22.4433 26.2863 22.057 26.7716 22.057H37.1716C37.647 22.057 38.0432 22.4433 38.0432 22.9286C38.0432 23.414 37.6569 23.8003 37.1716 23.8003Z"
            fill="#112645"
          />
        </g>
        <defs>
          <clipPath id="clip0_8217_5650">
            <rect width="26" height="24.2667" fill="white" transform="translate(15.5 16.8666)" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  if (highlighted) {
    return (
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.1251 12.9205C35.8874 12.7742 35.5949 12.7651 35.348 12.8839L34.1046 13.5057L32.8611 12.8839C32.6417 12.7742 32.3674 12.7742 32.148 12.8839L30.8954 13.5057L29.652 12.8839C29.4234 12.7742 29.1583 12.7742 28.9389 12.8839L27.7137 13.5057L26.4886 12.8839C26.2691 12.7742 25.9949 12.7742 25.7754 12.8839L24.5137 13.5057L23.2703 12.8839C23.0417 12.7742 22.7766 12.7742 22.5571 12.8839L21.3137 13.5057L20.0703 12.8839C19.8234 12.7559 19.5309 12.7742 19.2931 12.9205C19.0554 13.0668 18.9183 13.3228 18.9183 13.5971V27.2017H14.1091C13.8989 27.2017 13.6794 27.2382 13.4874 27.3205C13.2954 27.4028 13.1126 27.5125 12.9663 27.6679C12.8109 27.8142 12.7011 27.9971 12.6189 28.1982C12.5366 28.3902 12.5 28.5914 12.5 28.8017C12.5 31.3525 12.5 35.2017 17.3 35.2017H31.7C32.98 35.2017 34.1869 34.6988 35.092 33.7936C35.9971 32.8885 36.5 31.6817 36.5 30.4016V13.6062C36.5 13.3319 36.3537 13.0759 36.116 12.9297L36.1251 12.9205ZM28.1251 33.6017H17.3C16.1937 33.6017 15.4623 33.3822 14.9869 32.9068C14.2554 32.1754 14.1091 30.8039 14.1091 28.8199L14.1366 28.8017H26.9V30.4016C26.9 31.5719 27.3389 32.7239 28.1251 33.6017ZM27.7046 27.2017H20.5V14.8954L20.9389 15.1148C21.1583 15.2245 21.4326 15.2245 21.652 15.1148L22.8954 14.4931L24.1389 15.1148C24.3583 15.2245 24.6326 15.2245 24.852 15.1148L26.1137 14.4931L27.3389 15.1148C27.5674 15.2245 27.8326 15.2245 28.0611 15.1148L29.2954 14.4931L30.5297 15.1148C30.7491 15.2245 31.0234 15.2245 31.2429 15.1148L32.5046 14.4931L33.748 15.1148C33.9766 15.2245 34.2417 15.2245 34.4611 15.1148L34.9 14.8954V30.4016C34.9 31.2428 34.5617 32.0656 33.9583 32.6599C32.7514 33.8668 30.6394 33.8668 29.4326 32.6599C28.8383 32.0656 28.5 31.2428 28.5 30.4016V28.0062C28.5 27.5674 28.1434 27.2017 27.6954 27.2017H27.7046Z"
          fill="#EAEEF5"
        />
        <path
          d="M33.3 18.4062C33.3 17.9674 32.9434 17.6017 32.4954 17.6017H22.8954C22.4566 17.6017 22.0909 17.9582 22.0909 18.4062C22.0909 18.8542 22.4474 19.2108 22.8954 19.2108H32.4954C32.9343 19.2108 33.3 18.8542 33.3 18.4062Z"
          fill="#EAEEF5"
        />
        <path
          d="M33.3 22.4017C33.3 21.9628 32.9434 21.5971 32.4954 21.5971H26.0954C25.6566 21.5971 25.2909 21.9537 25.2909 22.4017C25.2909 22.8497 25.6474 23.2062 26.0954 23.2062H32.4954C32.9343 23.2062 33.3 22.8497 33.3 22.4017Z"
          fill="#EAEEF5"
        />
      </svg>
    );
  }
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.1251 12.9205C35.8874 12.7742 35.5949 12.7651 35.348 12.8839L34.1046 13.5057L32.8611 12.8839C32.6417 12.7742 32.3674 12.7742 32.148 12.8839L30.8954 13.5057L29.652 12.8839C29.4234 12.7742 29.1583 12.7742 28.9389 12.8839L27.7137 13.5057L26.4886 12.8839C26.2691 12.7742 25.9949 12.7742 25.7754 12.8839L24.5137 13.5057L23.2703 12.8839C23.0417 12.7742 22.7766 12.7742 22.5571 12.8839L21.3137 13.5057L20.0703 12.8839C19.8234 12.7559 19.5309 12.7742 19.2931 12.9205C19.0554 13.0668 18.9183 13.3228 18.9183 13.5971V27.2017H14.1091C13.8989 27.2017 13.6794 27.2382 13.4874 27.3205C13.2954 27.4028 13.1126 27.5125 12.9663 27.6679C12.8109 27.8142 12.7011 27.9971 12.6189 28.1982C12.5366 28.3902 12.5 28.5914 12.5 28.8017C12.5 31.3525 12.5 35.2017 17.3 35.2017H31.7C32.98 35.2017 34.1869 34.6988 35.092 33.7936C35.9971 32.8885 36.5 31.6817 36.5 30.4016V13.6062C36.5 13.3319 36.3537 13.0759 36.116 12.9297L36.1251 12.9205ZM28.1251 33.6017H17.3C16.1937 33.6017 15.4623 33.3822 14.9869 32.9068C14.2554 32.1754 14.1091 30.8039 14.1091 28.8199L14.1366 28.8017H26.9V30.4016C26.9 31.5719 27.3389 32.7239 28.1251 33.6017ZM27.7046 27.2017H20.5V14.8954L20.9389 15.1148C21.1583 15.2245 21.4326 15.2245 21.652 15.1148L22.8954 14.4931L24.1389 15.1148C24.3583 15.2245 24.6326 15.2245 24.852 15.1148L26.1137 14.4931L27.3389 15.1148C27.5674 15.2245 27.8326 15.2245 28.0611 15.1148L29.2954 14.4931L30.5297 15.1148C30.7491 15.2245 31.0234 15.2245 31.2429 15.1148L32.5046 14.4931L33.748 15.1148C33.9766 15.2245 34.2417 15.2245 34.4611 15.1148L34.9 14.8954V30.4016C34.9 31.2428 34.5617 32.0656 33.9583 32.6599C32.7514 33.8668 30.6394 33.8668 29.4326 32.6599C28.8383 32.0656 28.5 31.2428 28.5 30.4016V28.0062C28.5 27.5674 28.1434 27.2017 27.6954 27.2017H27.7046Z"
        fill="#112645"
      />
      <path
        d="M33.3 18.4062C33.3 17.9674 32.9434 17.6017 32.4954 17.6017H22.8954C22.4566 17.6017 22.0909 17.9582 22.0909 18.4062C22.0909 18.8542 22.4474 19.2108 22.8954 19.2108H32.4954C32.9343 19.2108 33.3 18.8542 33.3 18.4062Z"
        fill="#112645"
      />
      <path
        d="M33.3 22.4017C33.3 21.9628 32.9434 21.5971 32.4954 21.5971H26.0954C25.6566 21.5971 25.2909 21.9537 25.2909 22.4017C25.2909 22.8497 25.6474 23.2062 26.0954 23.2062H32.4954C32.9343 23.2062 33.3 22.8497 33.3 22.4017Z"
        fill="#112645"
      />
    </svg>
  );
}
