type IconProps = {
  selected?: boolean;
  highlighted?: boolean;
};

export default function GiftLayoutIcon({ selected, highlighted }: IconProps) {
  if (selected) {
    return (
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8182_8795)">
          <g clipPath="url(#clip1_8182_8795)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M32.35 18.95H31.78C32.06 18.45 32.22 17.89 32.22 17.3C32.22 16.39 31.86 15.52 31.22 14.88C30.59 14.25 29.72 13.89 28.82 13.89L28.8 14.78V13.89C27.51 13.87 26.33 14.5 25.39 15.54C24.95 16.03 24.56 16.61 24.24 17.25C23.91 16.61 23.53 16.03 23.09 15.54C22.16 14.5 20.98 13.87 19.69 13.89C18.79 13.89 17.92 14.25 17.28 14.89C16.64 15.53 16.28 16.4 16.28 17.31C16.28 17.89 16.44 18.45 16.72 18.96H16.15C15.1 18.96 14.25 19.81 14.25 20.86V22.89C14.25 23.94 15.1 24.79 16.15 24.79H16.28V30.99C16.28 31.76 16.59 32.5 17.13 33.05C17.68 33.6 18.42 33.9 19.19 33.9H29.32C30.09 33.9 30.83 33.59 31.38 33.05C31.93 32.5 32.23 31.76 32.23 30.99V24.79H32.36C33.41 24.79 34.26 23.94 34.26 22.89V20.86C34.26 19.81 33.41 18.96 32.36 18.96L32.35 18.95ZM26.72 16.73C27.41 15.97 28.13 15.65 28.79 15.66H28.81C29.25 15.66 29.67 15.83 29.97 16.14C30.27 16.45 30.45 16.87 30.45 17.3C30.45 17.73 30.28 18.15 29.97 18.46C29.66 18.77 29.24 18.94 28.81 18.94H25.45C25.78 18.03 26.22 17.27 26.72 16.72V16.73ZM18.53 18.47C18.22 18.16 18.05 17.74 18.05 17.31C18.05 16.88 18.22 16.46 18.53 16.15C18.84 15.84 19.26 15.67 19.69 15.67H19.71C20.37 15.66 21.1 15.97 21.78 16.74C22.28 17.3 22.72 18.06 23.05 18.96H19.69C19.25 18.96 18.84 18.79 18.53 18.48V18.47Z"
              fill="#112645"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_8182_8795">
            <rect width="24" height="24" fill="white" transform="translate(12.25 12)" />
          </clipPath>
          <clipPath id="clip1_8182_8795">
            <rect width="20" height="20" fill="white" transform="translate(14.25 13.89)" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  if (highlighted) {
    return (
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8217_4733)">
          <g clipPath="url(#clip1_8217_4733)">
            <path
              d="M32.35 18.95H31.78C32.06 18.45 32.22 17.89 32.22 17.3C32.22 16.39 31.86 15.52 31.22 14.88C30.59 14.25 29.72 13.89 28.82 13.89L28.8 14.78V13.89C27.51 13.87 26.33 14.5 25.39 15.54C24.95 16.03 24.56 16.61 24.24 17.25C23.91 16.61 23.53 16.03 23.09 15.54C22.16 14.5 20.98 13.87 19.69 13.89C18.79 13.89 17.92 14.25 17.28 14.89C16.64 15.53 16.28 16.4 16.28 17.31C16.28 17.89 16.44 18.45 16.72 18.96H16.15C15.1 18.96 14.25 19.81 14.25 20.86V22.89C14.25 23.94 15.1 24.79 16.15 24.79H16.28V30.99C16.28 31.76 16.59 32.5 17.13 33.05C17.67 33.6 18.42 33.9 19.19 33.9H29.32C30.09 33.9 30.83 33.59 31.38 33.05C31.93 32.5 32.23 31.76 32.23 30.99V24.79H32.36C33.41 24.79 34.26 23.94 34.26 22.89V20.86C34.26 19.81 33.41 18.96 32.36 18.96L32.35 18.95ZM26.72 16.73C27.41 15.97 28.13 15.65 28.79 15.66H28.81C29.25 15.66 29.67 15.83 29.97 16.14C30.27 16.45 30.45 16.87 30.45 17.3C30.45 17.73 30.28 18.15 29.97 18.46C29.66 18.77 29.24 18.94 28.81 18.94H25.45C25.78 18.03 26.22 17.27 26.72 16.72V16.73ZM18.53 18.47C18.22 18.16 18.05 17.74 18.05 17.31C18.05 16.88 18.22 16.46 18.53 16.15C18.84 15.84 19.26 15.67 19.69 15.67H19.71C20.37 15.66 21.1 15.97 21.78 16.74C22.28 17.3 22.72 18.06 23.05 18.96H19.69C19.25 18.96 18.84 18.79 18.53 18.48V18.47ZM16.02 22.88V20.85C16.02 20.78 16.08 20.72 16.15 20.72H23.37V23H16.15C16.08 23 16.02 22.94 16.02 22.87V22.88ZM18.38 31.78C18.17 31.57 18.05 31.28 18.05 30.97V24.77H23.37V32.11H19.19C18.89 32.11 18.6 31.99 18.38 31.78ZM30.45 30.98C30.45 31.28 30.33 31.57 30.12 31.79C29.91 32 29.62 32.12 29.31 32.12H25.13V24.78H30.45V30.98ZM32.48 22.88C32.48 22.95 32.42 23.01 32.35 23.01H25.13V20.73H32.35C32.42 20.73 32.48 20.79 32.48 20.86V22.89V22.88Z"
              fill="#EAEEF5"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_8217_4733">
            <rect width="24" height="24" fill="white" transform="translate(12.25 12)" />
          </clipPath>
          <clipPath id="clip1_8217_4733">
            <rect width="20" height="20" fill="white" transform="translate(14.25 13.89)" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8182_8844)">
        <g clipPath="url(#clip1_8182_8844)">
          <path
            d="M32.35 18.95H31.78C32.06 18.45 32.22 17.89 32.22 17.3C32.22 16.39 31.86 15.52 31.22 14.88C30.59 14.25 29.72 13.89 28.82 13.89L28.8 14.78V13.89C27.51 13.87 26.33 14.5 25.39 15.54C24.95 16.03 24.56 16.61 24.24 17.25C23.91 16.61 23.53 16.03 23.09 15.54C22.16 14.5 20.98 13.87 19.69 13.89C18.79 13.89 17.92 14.25 17.28 14.89C16.64 15.53 16.28 16.4 16.28 17.31C16.28 17.89 16.44 18.45 16.72 18.96H16.15C15.1 18.96 14.25 19.81 14.25 20.86V22.89C14.25 23.94 15.1 24.79 16.15 24.79H16.28V30.99C16.28 31.76 16.59 32.5 17.13 33.05C17.67 33.6 18.42 33.9 19.19 33.9H29.32C30.09 33.9 30.83 33.59 31.38 33.05C31.93 32.5 32.23 31.76 32.23 30.99V24.79H32.36C33.41 24.79 34.26 23.94 34.26 22.89V20.86C34.26 19.81 33.41 18.96 32.36 18.96L32.35 18.95ZM26.72 16.73C27.41 15.97 28.13 15.65 28.79 15.66H28.81C29.25 15.66 29.67 15.83 29.97 16.14C30.27 16.45 30.45 16.87 30.45 17.3C30.45 17.73 30.28 18.15 29.97 18.46C29.66 18.77 29.24 18.94 28.81 18.94H25.45C25.78 18.03 26.22 17.27 26.72 16.72V16.73ZM18.53 18.47C18.22 18.16 18.05 17.74 18.05 17.31C18.05 16.88 18.22 16.46 18.53 16.15C18.84 15.84 19.26 15.67 19.69 15.67H19.71C20.37 15.66 21.1 15.97 21.78 16.74C22.28 17.3 22.72 18.06 23.05 18.96H19.69C19.25 18.96 18.84 18.79 18.53 18.48V18.47ZM16.02 22.88V20.85C16.02 20.78 16.08 20.72 16.15 20.72H23.37V23H16.15C16.08 23 16.02 22.94 16.02 22.87V22.88ZM18.38 31.78C18.17 31.57 18.05 31.28 18.05 30.97V24.77H23.37V32.11H19.19C18.89 32.11 18.6 31.99 18.38 31.78ZM30.45 30.98C30.45 31.28 30.33 31.57 30.12 31.79C29.91 32 29.62 32.12 29.31 32.12H25.13V24.78H30.45V30.98ZM32.48 22.88C32.48 22.95 32.42 23.01 32.35 23.01H25.13V20.73H32.35C32.42 20.73 32.48 20.79 32.48 20.86V22.89V22.88Z"
            fill="#112645"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8182_8844">
          <rect width="24" height="24" fill="white" transform="translate(12.25 12)" />
        </clipPath>
        <clipPath id="clip1_8182_8844">
          <rect width="20" height="20" fill="white" transform="translate(14.25 13.89)" />
        </clipPath>
      </defs>
    </svg>
  );
}
