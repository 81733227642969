import React from 'react';

import {
  Box,
  Paper,
  Slide,
  Stack,
  alpha,
  Divider,
  MenuList,
  MenuItem,
  Collapse,
  Typography,
  ClickAwayListener,
} from '@mui/material';

import Help from 'src/assets/icons/help';
import Wallet from 'src/assets/icons/wallet';
import Leaving from 'src/assets/icons/leaving';
import Chevron from 'src/assets/icons/chevron';
import MyPoints from 'src/assets/icons/my-points';
import MyEvents from 'src/assets/icons/my-events';
import MyProfile from 'src/assets/icons/my-profile';
import MyExchanges from 'src/assets/icons/my-exchanges';

export interface SliderMenuProps {
  theme: any;
  sliderOpen: boolean;
  selectedTab: string;
  bottomNavHeight: number;
  TABS: Array<{ tab: string; title: string }>;
  menuItems: Array<{
    label: string;
    hasChevron?: boolean;
    isOpen?: boolean;
    subItems?: { label: string; path: string }[];
    breakLine?: boolean;
    onClick?: () => void;
    onClickSubItem?: (goTo: string) => void;
  }>;
  sliderMenuRef: React.RefObject<HTMLDivElement>;
  handleChangeTab: (tab: string) => void;
  onClickBottomNavigation: (newValue: number) => void;
}

export default function SliderMenuComponent({
  theme,
  sliderOpen,
  selectedTab,
  bottomNavHeight,
  TABS,
  menuItems,
  sliderMenuRef,
  handleChangeTab,
  onClickBottomNavigation,
}: SliderMenuProps) {
  const myVivaItems = [
    { icon: <Wallet />, label: 'CARTEIRINHA' },
    { icon: <MyPoints />, label: 'MEUS PONTOS' },
    { icon: <MyExchanges />, label: 'MINHAS TROCAS' },
    { icon: <MyProfile />, label: 'MEU PERFIL' },
    { icon: <MyEvents />, label: 'MEUS EVENTOS' },
  ];

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        e.preventDefault();
        onClickBottomNavigation(4);
      }}
    >
      <Slide direction="right" in={sliderOpen} mountOnEnter unmountOnExit>
        <Paper
          ref={sliderMenuRef}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: bottomNavHeight,
            width: '70%',
            backgroundColor:
              selectedTab === 'my-viva'
                ? theme.palette.yellowBrand[100]
                : theme.palette.blueBrand[500],
            zIndex: 10,
            overflowY: 'auto',
            maxHeight: `calc(100vh - ${bottomNavHeight}px)`,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 0,
          }}
          data-testid="slider-menu"
        >
          <Stack direction="row" spacing={5} justifyContent="space-between" p={12} mt={8}>
            {TABS.map((tab, index) => {
              const isTabSelected = selectedTab === tab.tab;

              const color = (() => {
                if (isTabSelected) {
                  if (selectedTab === 'my-viva') return theme.palette.blueBrand[500];
                  if (selectedTab === 'benefits') return theme.palette.common.white;
                }
                return theme.palette.common.hover;
              })();

              return (
                <Typography
                  key={`MENU_TAB_${index}`}
                  onClick={() => handleChangeTab(tab.tab)}
                  sx={{
                    color,
                    fontWeight: isTabSelected ? 'bold' : 'normal',
                    whiteSpace: 'nowrap',
                    ':hover': {
                      cursor: 'pointer',
                      color: isTabSelected ? color : theme.palette.common.hover,
                    },
                  }}
                  variant="h5"
                  data-testid={`tab-${tab.tab}`}
                >
                  {tab.title}
                </Typography>
              );
            })}
          </Stack>

          <MenuList
            sx={{
              color:
                selectedTab === 'my-viva'
                  ? theme.palette.blueBrand[500]
                  : theme.palette.common.white,
              flexGrow: 1,
              overflowY: 'auto',
            }}
            data-testid="menu-list"
          >
            {selectedTab === 'my-viva'
              ? myVivaItems.map((item, index) => (
                  <Box key={index}>
                    <MenuItem
                      sx={{
                        px: 12,
                        py: 2,
                        fontWeight: 400,
                        fontSize: 30,
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      data-testid={`my-viva-item-${item.label}`}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mr: 8,
                          mb: 3,
                        }}
                      >
                        {item.icon}
                      </Box>
                      {item.label}
                    </MenuItem>
                    <Divider />
                  </Box>
                ))
              : menuItems.map((item, index) => (
                  <Box key={index}>
                    <MenuItem
                      sx={{
                        px: 12,
                        py: 2,
                        fontWeight: 400,
                        fontSize: 30,
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        ...(item.breakLine && {
                          '@media (max-width: 374px)': {
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                          },
                        }),
                      }}
                      onClick={item.onClick}
                      data-testid={`menu-item-${item.label}`}
                    >
                      {item.label}
                      {item.hasChevron && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Chevron
                            orientation={item.isOpen ? 'up' : 'down'}
                            fill={
                              selectedTab === 'my-viva'
                                ? theme.palette.blueBrand[500]
                                : theme.palette.common.white
                            }
                          />
                        </Box>
                      )}
                    </MenuItem>

                    {item.hasChevron && (
                      <Collapse in={item.isOpen}>
                        <Stack spacing={4} px={16} mb={10}>
                          {item.subItems?.map((subItem, subIndex) => (
                            <Typography
                              key={subIndex}
                              variant="paragraphL500"
                              sx={{ cursor: 'pointer' }}
                              data-testid={`sub-item-${subItem.label}`}
                              onClick={() =>
                                item.onClickSubItem && item.onClickSubItem(subItem.path)
                              }
                            >
                              {subItem.label}
                            </Typography>
                          ))}
                        </Stack>
                      </Collapse>
                    )}
                    <Divider />
                  </Box>
                ))}
          </MenuList>

          <MenuItem
            sx={{
              height: '10%',
              minHeight: '10%',
              px: 12,
              py: 2,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor:
                selectedTab === 'my-viva'
                  ? theme.palette.yellowBrand[100]
                  : theme.palette.blueBrand[500],
              color:
                selectedTab === 'my-viva'
                  ? theme.palette.blueBrand[500]
                  : theme.palette.common.white,
            }}
            data-testid="help-item"
          >
            <Typography variant="h2">Ajuda</Typography>
            <Help
              fill={
                selectedTab === 'my-viva'
                  ? theme.palette.blueBrand[500]
                  : theme.palette.common.white
              }
            />
          </MenuItem>

          <MenuItem
            sx={{
              height: '10%',
              minHeight: '10%',
              px: 12,
              py: 2,
              fontWeight: 400,
              fontSize: 30,
              cursor: 'pointer',
              backgroundColor:
                selectedTab === 'my-viva'
                  ? theme.palette.yellowBrand[0]
                  : alpha(theme.palette.common.white, 0.2),
              color:
                selectedTab === 'my-viva'
                  ? theme.palette.blueBrand[500]
                  : theme.palette.common.white,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              '&:hover': {
                backgroundColor:
                  selectedTab === 'my-viva'
                    ? theme.palette.yellowBrand[0]
                    : theme.palette.grey[400],
              },
              '&.Mui-selected': {
                backgroundColor:
                  selectedTab === 'my-viva'
                    ? theme.palette.yellowBrand[0]
                    : theme.palette.grey[400],
              },
              '&.Mui-focusVisible': {
                backgroundColor:
                  selectedTab === 'my-viva'
                    ? theme.palette.yellowBrand[0]
                    : theme.palette.grey[400],
              },
            }}
            onClick={() => {
              console.log('Sair do Viva');
            }}
            data-testid="logout-item"
          >
            <Stack direction="row" spacing={8}>
              <Leaving
                fill={
                  selectedTab === 'my-viva'
                    ? theme.palette.blueBrand[500]
                    : theme.palette.common.white
                }
              />
              <Typography variant="h6">Sair do Viva</Typography>
            </Stack>
          </MenuItem>
        </Paper>
      </Slide>
    </ClickAwayListener>
  );
}
