import { Theme } from '@mui/material/styles';
import { CheckboxProps } from '@mui/material';

export function checkbox(theme: Theme) {
  const rootStyles = (ownerState: CheckboxProps) => {
    const primaryColor = ownerState.color === 'primary';

    const secondaryColor = ownerState.color === 'secondary';

    const disabled = !!ownerState.disabled;

    const defaultStyle = {
      borderRadius: 1,
      padding: 0,
      ...(primaryColor && {
        color: disabled ? theme.palette.grey[400] : theme.palette.blueBrand[400],
        '&.Mui-checked':{
          color: disabled ? theme.palette.grey[400] : theme.palette.blueBrand[400],
        }
      }),
      ...(secondaryColor && {
        color: disabled ? theme.palette.grey[400] : theme.palette.redBrand[200],
        '&.Mui-checked':{
          color: disabled ? theme.palette.grey[400] : theme.palette.redBrand[200],
        }
      })
    }


    return [defaultStyle];
  };

  return {
    MuiCheckbox: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: CheckboxProps }) => rootStyles(ownerState),
      },
    },
  };
}
