type IconProps = {
  selected?: boolean;
  highlighted?: boolean;
};

export default function SearchLayoutIcon({ selected, highlighted }: IconProps) {
  if (selected) {
    return (
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.75 30.9863C28.1683 30.9863 31.75 27.4046 31.75 22.9863C31.75 18.568 28.1683 14.9863 23.75 14.9863C19.3317 14.9863 15.75 18.568 15.75 22.9863C15.75 27.4046 19.3317 30.9863 23.75 30.9863Z"
          stroke="#112645"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.7492 32.9863L29.4492 28.6863"
          stroke="#112645"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  if (highlighted) {
    return (
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.75 30.9863C28.1683 30.9863 31.75 27.4046 31.75 22.9863C31.75 18.568 28.1683 14.9863 23.75 14.9863C19.3317 14.9863 15.75 18.568 15.75 22.9863C15.75 27.4046 19.3317 30.9863 23.75 30.9863Z"
          stroke="#EAEEF5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.7492 32.9863L29.4492 28.6863"
          stroke="#EAEEF5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.5 30.9863C27.9183 30.9863 31.5 27.4046 31.5 22.9863C31.5 18.568 27.9183 14.9863 23.5 14.9863C19.0817 14.9863 15.5 18.568 15.5 22.9863C15.5 27.4046 19.0817 30.9863 23.5 30.9863Z"
        stroke="#112645"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.4992 32.9863L29.1992 28.6863"
        stroke="#112645"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
