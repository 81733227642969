import { create } from 'zustand';

import { api } from 'src/utils/axios';

import { UserRepository } from 'src/repositories/auth/user.repository';

import { UserType, TermsType, WalletType } from 'src/types/user/userType';

type States = {
  isLoading: boolean;
  isLoadingSuggestion: boolean;
  isLoadingMyBalance: boolean;
  isLoadingDeleteAccount: boolean;
  user?: UserType;
  terms?: TermsType;
  wallet?: WalletType;
};

type loginParams = {
  code: string;
  setSession: (accessToken: string, refreshToken: string) => void;
};

type Actions = {
  setIsLoading: (loadingState: boolean) => void;
  setUser: (user: UserType) => void;
  login: (body: loginParams) => Promise<void>;
  userData: () => Promise<void>;
  myBalance: () => Promise<void>;
  termsCheck: () => Promise<void>;
  termsAccept: () => Promise<void>;
  sendSuggestion: (suggestion: string, area: string) => Promise<void>;
  deleteAccount: () => Promise<void>;
};

type UserStore = Actions & States;

const initialValues = {
  isLoading: false,
  isLoadingSuggestion: false,
  isLoadingMyBalance: false,
  isLoadingDeleteAccount: false,
  user: undefined,
  terms: undefined,
  wallet: undefined,
};

const userRepository = new UserRepository(api);

export const useUserStore = create<UserStore>((set: any, get: any) => ({
  ...initialValues,

  setIsLoading: (loadingState: boolean) => set({ isLoading: loadingState }),

  setUser: (userInfo: UserType) => set({ user: userInfo }),

  login: async ({ code, setSession }: loginParams) => {
    set({ isLoading: true });

    const response = await userRepository.login({ code });

    if (response.isLeft()) {
      set({ isLoading: false });
      throw new Error(response.value.message);
    }

    setSession(response.value.accessToken, response.value.refreshToken);

    try {
      await get().userData();
      get().myBalance();
    } catch (e) {
      set({ isLoading: false });
      throw new Error(response.value.message);
    }
  },

  userData: async () => {
    set({ isLoading: true });

    const response = await userRepository.userData();

    if (response.isLeft()) {
      set({ isLoading: false });
      throw new Error(response.value.message);
    }
    const updatedUser = {
      ...response.value,
      userLevel: 'resident',
      userImage: '/assets/Thumb.png',
      carPlate: '123SW002',
      birthDate: new Date(1997, 1, 15),
      phone: '+5511987654321',
      doubleVerification: false
    };

    set({ user: updatedUser });
    set({ isLoading: false });
  },

  myBalance: async () => {
    set({ isLoadingMyBalance: true });

    const response = await userRepository.myBalance();

    if (response.isLeft()) {
      set({ isLoadingMyBalance: false });
      throw new Error(response.value.message);
    }

    set({ wallet: response.value });
    set({ isLoadingMyBalance: false });
  },

  termsCheck: async () => {
    set({ isLoading: true });

    const response = await userRepository.termsCheck();

    if (response.isLeft()) {
      set({ isLoading: false });
      throw new Error(response.value.message);
    }

    set({ terms: response.value });
    set({ isLoading: false });
  },

  termsAccept: async () => {
    set({ isLoading: true });

    const response = await userRepository.termsAccept(get().terms?.terms?.version);

    if (response.isLeft()) {
      set({ isLoading: false });
      throw new Error(response.value.message);
    }

    try {
      await get().termsCheck();
    } catch (e) {
      set({ isLoading: false });
      throw new Error(response.value.message);
    }
  },

  sendSuggestion: async (suggestion: string, area: string) => {
    set({ isLoadingSuggestion: true });

    const response = await userRepository.sendSuggestion({ suggestion, area });

    if (response.isLeft()) {
      set({ isLoadingSuggestion: false });
      throw new Error(response.value.message);
    }

    set({ isLoadingSuggestion: false });
  },

  deleteAccount: async () => {
    set({ isLoadingDeleteAccount: true });

    const response = await userRepository.deleteAccount();
    if (response.isLeft()) {
      set({ isLoadingDeleteAccount: false });
      throw new Error(response.value.message);
    }
    set({ isLoadingDeleteAccount: false });
  },
}));
