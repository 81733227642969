export default function Wallet() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.99902 3.87494C3.92999 3.87494 3.87402 3.9309 3.87402 3.99994V6.99994C3.87402 7.06897 3.92999 7.12494 3.99902 7.12494H6.99902C7.06806 7.12494 7.12402 7.06897 7.12402 6.99994V3.99994C7.12402 3.9309 7.06806 3.87494 6.99902 3.87494H3.99902ZM2.12402 3.99994C2.12402 2.9644 2.96349 2.12494 3.99902 2.12494H6.99902C8.03456 2.12494 8.87402 2.9644 8.87402 3.99994V6.99994C8.87402 8.03547 8.03456 8.87494 6.99902 8.87494H3.99902C2.96349 8.87494 2.12402 8.03547 2.12402 6.99994V3.99994Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.001 3.87494C16.9319 3.87494 16.876 3.9309 16.876 3.99994V6.99994C16.876 7.06897 16.9319 7.12494 17.001 7.12494H20.001C20.07 7.12494 20.126 7.06897 20.126 6.99994V3.99994C20.126 3.9309 20.07 3.87494 20.001 3.87494H17.001ZM15.126 3.99994C15.126 2.9644 15.9654 2.12494 17.001 2.12494H20.001C21.0365 2.12494 21.876 2.9644 21.876 3.99994V6.99994C21.876 8.03547 21.0365 8.87494 20.001 8.87494H17.001C15.9654 8.87494 15.126 8.03547 15.126 6.99994V3.99994Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.99902 16.8751C3.92999 16.8751 3.87402 16.931 3.87402 17.0001V20.0001C3.87402 20.0691 3.92999 20.1251 3.99902 20.1251H6.99902C7.06806 20.1251 7.12402 20.0691 7.12402 20.0001V17.0001C7.12402 16.931 7.06806 16.8751 6.99902 16.8751H3.99902ZM2.12402 17.0001C2.12402 15.9645 2.96349 15.1251 3.99902 15.1251H6.99902C8.03456 15.1251 8.87402 15.9645 8.87402 17.0001V20.0001C8.87402 21.0356 8.03456 21.8751 6.99902 21.8751H3.99902C2.96349 21.8751 2.12402 21.0356 2.12402 20.0001V17.0001Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.968 15.9671C16.5072 15.428 17.2385 15.1251 18.001 15.1251H21.001C21.4842 15.1251 21.876 15.5168 21.876 16.0001C21.876 16.4833 21.4842 16.8751 21.001 16.8751H18.001C17.7026 16.8751 17.4165 16.9936 17.2055 17.2046C16.9945 17.4155 16.876 17.7017 16.876 18.0001V21.0001C16.876 21.4833 16.4842 21.8751 16.001 21.8751C15.5177 21.8751 15.126 21.4833 15.126 21.0001V18.0001C15.126 17.2376 15.4289 16.5063 15.968 15.9671Z"
          fill="#112645"
        />
        <path
          d="M21 21V21.01"
          stroke="#112645"
          strokeWidth="1.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.001 6.12506C12.4842 6.12506 12.876 6.51681 12.876 7.00006V10.0001C12.876 10.7626 12.5731 11.4938 12.0339 12.033C11.4947 12.5722 10.7635 12.8751 10.001 12.8751H7.00098C6.51773 12.8751 6.12598 12.4833 6.12598 12.0001C6.12598 11.5168 6.51773 11.1251 7.00098 11.1251H10.001C10.2993 11.1251 10.5855 11.0065 10.7965 10.7956C11.0075 10.5846 11.126 10.2984 11.126 10.0001V7.00006C11.126 6.51681 11.5177 6.12506 12.001 6.12506Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.12402 11.9999C2.12402 11.5167 2.51577 11.1249 2.99902 11.1249H3.00902C3.49227 11.1249 3.88402 11.5167 3.88402 11.9999C3.88402 12.4832 3.49227 12.8749 3.00902 12.8749H2.99902C2.51577 12.8749 2.12402 12.4832 2.12402 11.9999Z"
          fill="#112645"
        />
        <path
          d="M12 3H12.01"
          stroke="#112645"
          strokeWidth="1.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.999 15.1251C12.4823 15.1251 12.874 15.5168 12.874 16.0001V16.0101C12.874 16.4933 12.4823 16.8851 11.999 16.8851C11.5158 16.8851 11.124 16.4933 11.124 16.0101V16.0001C11.124 15.5168 11.5158 15.1251 11.999 15.1251Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.126 11.9999C15.126 11.5167 15.5177 11.1249 16.001 11.1249H17.001C17.4842 11.1249 17.876 11.5167 17.876 11.9999C17.876 12.4832 17.4842 12.8749 17.001 12.8749H16.001C15.5177 12.8749 15.126 12.4832 15.126 11.9999Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.999 11.1249C21.4823 11.1249 21.874 11.5167 21.874 11.9999V12.0099C21.874 12.4932 21.4823 12.8849 20.999 12.8849C20.5158 12.8849 20.124 12.4932 20.124 12.0099V11.9999C20.124 11.5167 20.5158 11.1249 20.999 11.1249Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.999 19.125C12.4823 19.125 12.874 19.5168 12.874 20V21C12.874 21.4832 12.4823 21.875 11.999 21.875C11.5158 21.875 11.124 21.4832 11.124 21V20C11.124 19.5168 11.5158 19.125 11.999 19.125Z"
          fill="#112645"
        />
      </g>
    </svg>
  );
}
