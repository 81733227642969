type IconProps = {
  selected?: boolean;
};

export default function GroupLayoutIcon({ selected }: IconProps) {
  if (selected) {
    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5 15.4584C14.5 14.9291 14.9341 14.5 15.4696 14.5H21.0913C21.6267 14.5 22.0608 14.9291 22.0608 15.4584V21.1248C22.0608 21.6541 21.6267 22.0832 21.0913 22.0832H15.4696C14.9341 22.0832 14.5 21.6541 14.5 21.1248V15.4584Z"
          fill="#EAEEF5"
        />
        <path
          d="M25.9392 15.4584C25.9392 14.9291 26.3733 14.5 26.9087 14.5H32.5304C33.0659 14.5 33.5 14.9291 33.5 15.4584V21.1248C33.5 21.6541 33.0659 22.0832 32.5304 22.0832H26.9087C26.3733 22.0832 25.9392 21.6541 25.9392 21.1248V15.4584Z"
          fill="#EAEEF5"
        />
        <path
          d="M14.5 26.8752C14.5 26.3459 14.9341 25.9168 15.4696 25.9168H21.0913C21.6267 25.9168 22.0608 26.3459 22.0608 26.8752V32.5416C22.0608 33.0709 21.6267 33.5 21.0913 33.5H15.4696C14.9341 33.5 14.5 33.0709 14.5 32.5416V26.8752Z"
          fill="#EAEEF5"
        />
        <path
          d="M25.9392 26.8752C25.9392 26.3459 26.3733 25.9168 26.9087 25.9168H32.5304C33.0659 25.9168 33.5 26.3459 33.5 26.8752V32.5416C33.5 33.0709 33.0659 33.5 32.5304 33.5H26.9087C26.3733 33.5 25.9392 33.0709 25.9392 32.5416V26.8752Z"
          fill="#EAEEF5"
        />
        <path
          d="M14.5 15.4584C14.5 14.9291 14.9341 14.5 15.4696 14.5H21.0913C21.6267 14.5 22.0608 14.9291 22.0608 15.4584V21.1248C22.0608 21.6541 21.6267 22.0832 21.0913 22.0832H15.4696C14.9341 22.0832 14.5 21.6541 14.5 21.1248V15.4584Z"
          stroke="#EAEEF5"
          strokeWidth="1.75"
        />
        <path
          d="M25.9392 15.4584C25.9392 14.9291 26.3733 14.5 26.9087 14.5H32.5304C33.0659 14.5 33.5 14.9291 33.5 15.4584V21.1248C33.5 21.6541 33.0659 22.0832 32.5304 22.0832H26.9087C26.3733 22.0832 25.9392 21.6541 25.9392 21.1248V15.4584Z"
          stroke="#EAEEF5"
          strokeWidth="1.75"
        />
        <path
          d="M14.5 26.8752C14.5 26.3459 14.9341 25.9168 15.4696 25.9168H21.0913C21.6267 25.9168 22.0608 26.3459 22.0608 26.8752V32.5416C22.0608 33.0709 21.6267 33.5 21.0913 33.5H15.4696C14.9341 33.5 14.5 33.0709 14.5 32.5416V26.8752Z"
          stroke="#EAEEF5"
          strokeWidth="1.75"
        />
        <path
          d="M25.9392 26.8752C25.9392 26.3459 26.3733 25.9168 26.9087 25.9168H32.5304C33.0659 25.9168 33.5 26.3459 33.5 26.8752V32.5416C33.5 33.0709 33.0659 33.5 32.5304 33.5H26.9087C26.3733 33.5 25.9392 33.0709 25.9392 32.5416V26.8752Z"
          stroke="#EAEEF5"
          strokeWidth="1.75"
        />
      </svg>
    );
  }
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 15.4584C14.5 14.9291 14.9341 14.5 15.4696 14.5H21.0913C21.6267 14.5 22.0608 14.9291 22.0608 15.4584V21.1248C22.0608 21.6541 21.6267 22.0832 21.0913 22.0832H15.4696C14.9341 22.0832 14.5 21.6541 14.5 21.1248V15.4584Z"
        stroke="#112645"
        strokeWidth="1.75"
      />
      <path
        d="M25.9392 15.4584C25.9392 14.9291 26.3733 14.5 26.9087 14.5H32.5304C33.0659 14.5 33.5 14.9291 33.5 15.4584V21.1248C33.5 21.6541 33.0659 22.0832 32.5304 22.0832H26.9087C26.3733 22.0832 25.9392 21.6541 25.9392 21.1248V15.4584Z"
        stroke="#112645"
        strokeWidth="1.75"
      />
      <path
        d="M14.5 26.8752C14.5 26.3459 14.9341 25.9168 15.4696 25.9168H21.0913C21.6267 25.9168 22.0608 26.3459 22.0608 26.8752V32.5416C22.0608 33.0709 21.6267 33.5 21.0913 33.5H15.4696C14.9341 33.5 14.5 33.0709 14.5 32.5416V26.8752Z"
        stroke="#112645"
        strokeWidth="1.75"
      />
      <path
        d="M25.9392 26.8752C25.9392 26.3459 26.3733 25.9168 26.9087 25.9168H32.5304C33.0659 25.9168 33.5 26.3459 33.5 26.8752V32.5416C33.5 33.0709 33.0659 33.5 32.5304 33.5H26.9087C26.3733 33.5 25.9392 33.0709 25.9392 32.5416V26.8752Z"
        stroke="#112645"
        strokeWidth="1.75"
      />
    </svg>
  );
}
