import { Either } from 'src/utils/either';
import { endpoints } from 'src/utils/axios';

import { httpResponseHandler } from 'src/http/http-response-handler';

import {
  HttpTypes,
  BadRequestError,
  ConnectionError,
  UnexpectedError,
  AccessDeniedError,
  InternalServerError,
  TooManyRequestsError,
} from '../../http';

export class UserRepository {
  constructor(private api: HttpTypes.HttpClient) {}

  async login(
    body: { code: string }
  ): Promise<
    Either<
      | BadRequestError
      | UnexpectedError
      | InternalServerError
      | AccessDeniedError
      | ConnectionError
      | TooManyRequestsError,
      any | undefined
    >
  > {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url: endpoints.auth.login,
      body,
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async userData(): Promise<
    Either<
      | BadRequestError
      | UnexpectedError
      | InternalServerError
      | AccessDeniedError
      | ConnectionError
      | TooManyRequestsError,
      any | undefined
    >
  > {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: endpoints.auth.userData,
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async myBalance(): Promise<
    Either<
      | BadRequestError
      | UnexpectedError
      | InternalServerError
      | AccessDeniedError
      | ConnectionError
      | TooManyRequestsError,
      any | undefined
    >
  > {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: endpoints.wallet.myBalance,
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async termsCheck(): Promise<
    Either<
      | BadRequestError
      | UnexpectedError
      | InternalServerError
      | AccessDeniedError
      | ConnectionError
      | TooManyRequestsError,
      any | undefined
    >
  > {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: endpoints.auth.termsCheck,
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async termsAccept(termsVersion: string): Promise<
    Either<
      | BadRequestError
      | UnexpectedError
      | InternalServerError
      | AccessDeniedError
      | ConnectionError
      | TooManyRequestsError,
      any | undefined
    >
  > {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url: endpoints.auth.termsAccept,
      params: {
        termsVersion
      } as any
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async sendSuggestion(body: {suggestion: string, area: string}): Promise<
    Either<
      | BadRequestError
      | UnexpectedError
      | InternalServerError
      | AccessDeniedError
      | ConnectionError
      | TooManyRequestsError,
      any | undefined
    >
  > {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url: endpoints.suggestion.sendSuggestion,
      body
    };

    const options: HttpTypes.RequestOptions = {
      data: { message: 'foi' },
      status: 200,
      delay: 2000,
    };

    const response = await this.api.fakeRequest(request, options);

    return httpResponseHandler(response);
  }

  async deleteAccount(): Promise<
  Either<
    | BadRequestError
    | UnexpectedError
    | InternalServerError
    | AccessDeniedError
    | ConnectionError
    | TooManyRequestsError,
    any | undefined
  >
> {
  const request: HttpTypes.HttpRequest = {
    method: 'delete',
    url: endpoints.auth.deleteAccount,
  };

  const options: HttpTypes.RequestOptions = {
    data: { message: 'Account deletion request sent.' },
    status: 200,
    delay: 2000,
  };

  const response = await this.api.fakeRequest(request, options);

  return httpResponseHandler(response);
}

}
