export default function MyExchanges() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4" clipPath="url(#clip0_10584_430)">
        <g clipPath="url(#clip1_10584_430)">
          <path
            d="M20.1 6.95002H19.53C19.81 6.45002 19.97 5.89002 19.97 5.30002C19.97 4.39002 19.61 3.52002 18.97 2.88002C18.34 2.25002 17.47 1.89002 16.57 1.89002L16.55 2.78002V1.89002C15.26 1.87002 14.08 2.50002 13.14 3.54002C12.7 4.03002 12.31 4.61002 11.99 5.25002C11.66 4.61002 11.28 4.03002 10.84 3.54002C9.91 2.50002 8.73 1.87002 7.44 1.89002C6.54 1.89002 5.67 2.25002 5.03 2.89002C4.39 3.53002 4.03 4.40002 4.03 5.31002C4.03 5.89002 4.19 6.45002 4.47 6.96002H3.9C2.85 6.96002 2 7.81002 2 8.86002V10.89C2 11.94 2.85 12.79 3.9 12.79H4.03V18.99C4.03 19.76 4.34 20.5 4.88 21.05C5.42 21.6 6.17 21.9 6.94 21.9H17.07C17.84 21.9 18.58 21.59 19.13 21.05C19.68 20.5 19.98 19.76 19.98 18.99V12.79H20.11C21.16 12.79 22.01 11.94 22.01 10.89V8.86002C22.01 7.81002 21.16 6.96002 20.11 6.96002L20.1 6.95002ZM14.47 4.73002C15.16 3.97002 15.88 3.65002 16.54 3.66002H16.56C17 3.66002 17.42 3.83002 17.72 4.14002C18.02 4.45002 18.2 4.87002 18.2 5.30002C18.2 5.73002 18.03 6.15002 17.72 6.46002C17.41 6.77002 16.99 6.94002 16.56 6.94002H13.2C13.53 6.03002 13.97 5.27002 14.47 4.72002V4.73002ZM6.28 6.47002C5.97 6.16002 5.8 5.74002 5.8 5.31002C5.8 4.88002 5.97 4.46002 6.28 4.15002C6.59 3.84002 7.01 3.67002 7.44 3.67002H7.46C8.12 3.66002 8.85 3.97002 9.53 4.74002C10.03 5.30002 10.47 6.06002 10.8 6.96002H7.44C7 6.96002 6.59 6.79002 6.28 6.48002V6.47002ZM3.77 10.88V8.85002C3.77 8.78002 3.83 8.72002 3.9 8.72002H11.12V11H3.9C3.83 11 3.77 10.94 3.77 10.87V10.88ZM6.13 19.78C5.92 19.57 5.8 19.28 5.8 18.97V12.77H11.12V20.11H6.94C6.64 20.11 6.35 19.99 6.13 19.78ZM18.2 18.98C18.2 19.28 18.08 19.57 17.87 19.79C17.66 20 17.37 20.12 17.06 20.12H12.88V12.78H18.2V18.98ZM20.23 10.88C20.23 10.95 20.17 11.01 20.1 11.01H12.88V8.73002H20.1C20.17 8.73002 20.23 8.79002 20.23 8.86002V10.89V10.88Z"
            fill="#112645"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10584_430">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_10584_430">
          <rect width="20" height="20" fill="white" transform="translate(2 1.89001)" />
        </clipPath>
      </defs>
    </svg>
  );
}
