export default function MyEvents() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.23163 11.4343C5.59884 11.5648 5.7908 11.9684 5.66039 12.3357L1.86617 23.024L12.5495 19.2383C12.9168 19.1082 13.32 19.3005 13.4501 19.668C13.5803 20.0355 13.388 20.4389 13.0206 20.5691L0.941174 24.8495C0.684679 24.9404 0.398802 24.8756 0.206471 24.683C0.014141 24.4905 -0.0503601 24.2044 0.0407046 23.9479L4.33061 11.8633C4.46102 11.4959 4.86442 11.3039 5.23163 11.4343Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25696 2.72551C2.25696 2.33567 2.57286 2.01964 2.96254 2.01964H2.97383C3.36351 2.01964 3.6794 2.33567 3.6794 2.72551C3.6794 3.11536 3.36351 3.43139 2.97383 3.43139H2.96254C2.57286 3.43139 2.25696 3.11536 2.25696 2.72551Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5776 8.37296C22.5776 7.98312 22.8935 7.66709 23.2831 7.66709H23.2944C23.6841 7.66709 24 7.98312 24 8.37296C24 8.76281 23.6841 9.07884 23.2944 9.07884H23.2831C22.8935 9.07884 22.5776 8.76281 22.5776 8.37296Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6742 1.59655C14.6742 1.20671 14.9901 0.890676 15.3798 0.890676H15.3911C15.7808 0.890676 16.0967 1.20671 16.0967 1.59655C16.0967 1.9864 15.7808 2.30243 15.3911 2.30243H15.3798C14.9901 2.30243 14.6742 1.9864 14.6742 1.59655Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5776 21.9258C22.5776 21.5359 22.8935 21.2199 23.2831 21.2199H23.2944C23.6841 21.2199 24 21.5359 24 21.9258C24 22.3156 23.6841 22.6317 23.2944 22.6317H23.2831C22.8935 22.6317 22.5776 22.3156 22.5776 21.9258Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9532 1.37197C24.0769 1.74164 23.8777 2.14166 23.5082 2.26544L20.9794 3.11249C20.4147 3.30066 19.9318 3.67955 19.6152 4.18376C19.2986 4.68796 19.1669 5.28653 19.2425 5.87714L19.2436 5.88533C19.4046 7.27033 18.3268 8.51367 16.9057 8.51367H16.4768C15.8362 8.51367 15.2986 8.95864 15.183 9.56621L15.182 9.57131L14.9449 10.7685C14.8692 11.1509 14.4979 11.3995 14.1157 11.3237C13.7334 11.248 13.4849 10.8765 13.5607 10.4941L13.7973 9.29932C14.0439 8.01094 15.1764 7.10192 16.4768 7.10192H16.9057C17.4705 7.10192 17.9049 6.60795 17.8422 6.05174C17.7263 5.13819 17.9306 4.21264 18.4203 3.43278C18.9108 2.65169 19.6571 2.06524 20.5319 1.77361C20.5317 1.77367 20.532 1.77355 20.5319 1.77361L23.0601 0.926729C23.4297 0.802954 23.8295 1.00229 23.9532 1.37197Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4281 14.7784C19.6858 13.3124 21.2296 12.4416 22.6177 12.9906L22.6217 12.9922L23.5474 13.3649C23.9089 13.5104 24.0841 13.9216 23.9386 14.2832C23.7931 14.6449 23.3821 14.8201 23.0206 14.6746L22.0972 14.3028C21.5455 14.0858 20.9249 14.4321 20.8188 15.018C20.6372 16.1495 19.6534 16.9844 18.5087 16.9844H17.6394C17.2497 16.9844 16.9338 16.6684 16.9338 16.2785C16.9338 15.8887 17.2497 15.5726 17.6394 15.5726H18.5087C18.9644 15.5726 19.356 15.2368 19.426 14.7911L19.4281 14.7784Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6007 0.941241C10.9622 0.795696 11.3732 0.970889 11.5187 1.33255L11.8928 2.26266C12.4416 3.65133 11.5711 5.19586 10.1057 5.45362C10.0983 5.45493 10.0908 5.45612 10.0833 5.45718C9.65376 5.51858 9.31184 5.90949 9.31184 6.37345V7.24309C9.31184 7.63293 8.99595 7.94896 8.60627 7.94896C8.21659 7.94896 7.90069 7.63293 7.90069 7.24309V6.37345C7.90069 5.2382 8.72624 4.23216 9.87003 4.06163C10.4534 3.95354 10.7976 3.33421 10.5812 2.78346L10.2096 1.85961C10.0641 1.49795 10.2392 1.08679 10.6007 0.941241Z"
          fill="#112645"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.19439 12.1177C5.91629 12.1194 5.78357 12.1945 5.71811 12.26C5.65264 12.3255 5.57755 12.4583 5.57585 12.7365C5.57411 13.019 5.65118 13.3977 5.83466 13.856C6.20062 14.77 6.93745 15.8698 7.97595 16.9087C9.01445 17.9477 10.1137 18.6848 11.0274 19.0509C11.4855 19.2345 11.864 19.3116 12.1464 19.3099C12.4245 19.3082 12.5573 19.233 12.6227 19.1675C12.6882 19.102 12.7633 18.9693 12.765 18.6911C12.7667 18.4085 12.6897 18.0298 12.5062 17.5715C12.1402 16.6575 11.4034 15.5577 10.3649 14.5188C9.32638 13.4798 8.2271 12.7427 7.31346 12.3766C6.85537 12.193 6.47682 12.1159 6.19439 12.1177ZM7.83817 11.0661C8.96788 11.5188 10.2224 12.3797 11.3627 13.5205C12.503 14.6613 13.3636 15.9164 13.8161 17.0466C14.0419 17.6104 14.1793 18.1762 14.1761 18.6997C14.1729 19.2276 14.0236 19.7626 13.6206 20.1658C13.2175 20.569 12.6828 20.7184 12.1551 20.7216C11.6318 20.7248 11.0662 20.5873 10.5027 20.3615C9.37295 19.9088 8.11844 19.0478 6.97812 17.907C5.8378 16.7662 4.9772 15.5111 4.52469 14.381C4.29895 13.8171 4.16151 13.2514 4.16472 12.7278C4.16795 12.1999 4.31723 11.6649 4.72027 11.2617C5.12331 10.8585 5.65807 10.7092 6.18574 10.7059C6.70907 10.7027 7.27458 10.8402 7.83817 11.0661Z"
          fill="#112645"
        />
      </g>
    </svg>
  );
}
