import React, { lazy, Suspense } from 'react';
import { render, RenderResult } from '@testing-library/react';
import { Navigate, useRoutes, useLocation, MemoryRouter } from 'react-router-dom';

import PointsLayout from 'src/layouts/points/points-layout';

import { SplashScreen } from 'src/components/loading-screen';

import { paths } from '../paths';
import { authRoutes } from './auth';
import { errorRoutes } from './error';
import { AuthGuard } from '../../auth/guard';
import { DefaultLayout } from '../../layouts/index';

// PAGE_IMPORTS
const EventSchedule = lazy(() => import("../../modules/benefits/event-schedule/event-schedule"));
const EventScheduled = lazy(() => import("../../modules/benefits/event-scheduled/event-scheduled"));
const AboutEvent = lazy(() => import("../../modules/benefits/about-event/about-event"));
const MyEvents = lazy(() => import("../../modules/benefits/my-events/my-events"));
const Events = lazy(() => import("../../modules/benefits/events/events"));
const MyProfile = lazy(() => import("../../modules/user/my-profile/my-profile"));
const MyLevel = lazy(() => import("../../modules/user/my-level/my-level"));
const DeleteAccount = lazy(() => import("../../modules/user/delete-account/delete-account"));
const RegistrationData = lazy(() => import("../../modules/user/registration-data/registration-data"));
const HomolMotor = lazy(() => import("../../modules/test/homol-motor/homol-motor"));
const MyExchanges = lazy(() => import("../../modules/user/my-exchanges/my-exchanges"));
const AllProducts = lazy(() => import("../../modules/benefits/all-products/all-products"));
const ProductRescue = lazy(() => import("../../modules/benefits/product-rescue/product-rescue"));
const ProductDetails = lazy(() => import("../../modules/benefits/product-details/product-details"));
const Products = lazy(() => import("../../modules/benefits/products/products"));
const BenefitsRescue = lazy(() => import('../../modules/benefits/discount-rescue/discount-rescue'));
const BenefitsDetails = lazy(
  () => import('../../modules/benefits/discount-details/discount-details')
);
const CouponRescue = lazy(() => import('../../modules/benefits/coupon-rescue/coupon-rescue'));
const CouponDetails = lazy(() => import('../../modules/benefits/coupon-details/coupon-details'));
const AllDiscountsCoupons = lazy(
  () => import('../../modules/benefits/all-discounts-coupons/all-discounts-coupons')
);
const AllBenefitsCoupons = lazy(
  () => import('../../modules/benefits/all-benefits-coupons/all-benefits-coupons')
);
const Coupons = lazy(() => import('../../modules/benefits/coupons/coupons'));
const ExperienceRescue = lazy(
  () => import('../../modules/benefits/experience-rescue/experience-rescue')
);
const ExperienceDetails = lazy(
  () => import('../../modules/benefits/experience-details/experience-details')
);
const Experiences = lazy(() => import('../../modules/benefits/experiences/experiences'));
const Home = lazy(() => import('../../modules/home/home/home'));
const Test = lazy(() => import('../../modules/test/test/test'));
const AuthError = lazy(() => import('../../modules/auth/auth-error/auth-error'));
const Register = lazy(() => import('../../modules/auth/register/register'));

export const renderWithRouter = (ui: React.ReactElement): RenderResult =>
  render(<MemoryRouter>{ui}</MemoryRouter>);

export default function Router() {
  const location = useLocation();
  return useRoutes([
    {
      path: '/',
      element: (
        <Suspense fallback={<SplashScreen />}>
          <DefaultLayout>
            <Navigate to={`${paths.home.root}${location.search}`} replace />
          </DefaultLayout>
        </Suspense>
      ),
    },
    ...authRoutes,

    // RELATION_PATH_PAGES
    { path: paths.events.schedule(':id'), element:
      <AuthGuard>
        <DefaultLayout>
          <EventSchedule />
        </DefaultLayout>
      </AuthGuard>
    },
    { path: paths.events.scheduled(':id'), element:
      <AuthGuard>
        <PointsLayout>
          <DefaultLayout>
            <EventScheduled />
          </DefaultLayout>
        </PointsLayout>
      </AuthGuard>
    },
    { path: paths.events.about(':id'), element:
      <AuthGuard>
        <DefaultLayout>
          <AboutEvent />
        </DefaultLayout>
      </AuthGuard>
    },
    { path: paths.events.my, element:
      <AuthGuard>
        <DefaultLayout>
          <MyEvents />
        </DefaultLayout>
      </AuthGuard>
    },
    { path: paths.events.root, element:
      <AuthGuard>
        <DefaultLayout>
          <Events />
        </DefaultLayout>
      </AuthGuard>
    },
    { path: paths.myProfile.root, element:
      <AuthGuard>
        <DefaultLayout>
          <MyProfile />
          </DefaultLayout>
          </AuthGuard>
    },
    { path: paths.myLevel.root, element:
      <AuthGuard>
        <DefaultLayout>
          <MyLevel />
        </DefaultLayout>
      </AuthGuard>
    },
    { path: paths.deleteAccount.root, element:
      <AuthGuard>
        <DefaultLayout>
          <DeleteAccount />
        </DefaultLayout>
      </AuthGuard>
    },
    { path: paths.registrationData.root, element:
      <AuthGuard>
        <DefaultLayout>
          <RegistrationData />
        </DefaultLayout>
      </AuthGuard>
    },
    { path: paths.homolMotor.root, element:
      <DefaultLayout>
        <HomolMotor />
      </DefaultLayout>
    },
    { path: paths.myExchanges.root, element:
      <AuthGuard>
        <DefaultLayout>
          <MyExchanges />
        </DefaultLayout>
      </AuthGuard>
    },
    { path: paths.products.all, element:
      <AuthGuard>
        <PointsLayout>
          <DefaultLayout>
            <AllProducts />
          </DefaultLayout>
        </PointsLayout>
      </AuthGuard>
    },
    { path: paths.products.rescue(':id'), element:
      <AuthGuard>
        <PointsLayout>
          <DefaultLayout>
            <ProductRescue />
          </DefaultLayout>
        </PointsLayout>
      </AuthGuard>
    },
    { path: paths.products.details(':id'), element:
      <AuthGuard>
        <PointsLayout>
          <DefaultLayout>
            <ProductDetails />
          </DefaultLayout>
        </PointsLayout>
      </AuthGuard>
    },
    { path: paths.products.root, element:
      <AuthGuard>
        <PointsLayout>
          <DefaultLayout>
            <Products />
          </DefaultLayout>
        </PointsLayout>
      </AuthGuard>
    },
    { path: paths.experiences.rescue(':id'), element:
      <AuthGuard>
        <PointsLayout>
          <DefaultLayout>
            <ExperienceRescue />
          </DefaultLayout>
        </PointsLayout>
      </AuthGuard>
    },
    {
      path: paths.coupons.benefitsRescue(':id'),
      element: (
        <AuthGuard>
          <PointsLayout>
            <DefaultLayout>
              <BenefitsRescue />
            </DefaultLayout>
          </PointsLayout>
        </AuthGuard>
      ),
    },
    {
      path: paths.coupons.benefitsDetails(':id'),
      element: (
        <AuthGuard>
          <PointsLayout>
            <DefaultLayout>
              <BenefitsDetails />
            </DefaultLayout>
          </PointsLayout>
        </AuthGuard>
      ),
    },
    {
      path: paths.coupons.couponRescue(':id'),
      element: (
        <AuthGuard>
          <PointsLayout>
            <DefaultLayout>
              <CouponRescue />
            </DefaultLayout>
          </PointsLayout>
        </AuthGuard>
      ),
    },
    {
      path: paths.coupons.couponDetails(':id'),
      element: (
        <AuthGuard>
          <PointsLayout>
            <DefaultLayout>
              <CouponDetails />
            </DefaultLayout>
          </PointsLayout>
        </AuthGuard>
      ),
    },
    {
      path: paths.coupons.discounts,
      element: (
        <AuthGuard>
          <PointsLayout>
            <DefaultLayout>
              <AllDiscountsCoupons />
            </DefaultLayout>
          </PointsLayout>
        </AuthGuard>
      ),
    },
    {
      path: paths.coupons.benefits,
      element: (
        <AuthGuard>
          <PointsLayout>
            <DefaultLayout>
              <AllBenefitsCoupons />
            </DefaultLayout>
          </PointsLayout>
        </AuthGuard>
      ),
    },
    {
      path: paths.coupons.root,
      element: (
        <AuthGuard>
          <PointsLayout>
            <DefaultLayout>
              <Coupons />
            </DefaultLayout>
          </PointsLayout>
        </AuthGuard>
      ),
    },
    {
      path: paths.experiences.rescue(':id'),
      element: (
        <AuthGuard>
          <PointsLayout>
            <DefaultLayout>
              <ExperienceRescue />
            </DefaultLayout>
          </PointsLayout>
        </AuthGuard>
      ),
    },
    {
      path: paths.experiences.details(':id'),
      element: (
        <AuthGuard>
          <PointsLayout>
            <DefaultLayout>
              <ExperienceDetails />
            </DefaultLayout>
          </PointsLayout>
        </AuthGuard>
      ),
    },
    {
      path: paths.experiences.root,
      element: (
        <AuthGuard>
          <PointsLayout>
            <DefaultLayout>
              <Experiences />
            </DefaultLayout>
          </PointsLayout>
        </AuthGuard>
      ),
    },
    {
      path: paths.home.root,
      element: (
        <AuthGuard>
          <DefaultLayout>
            <Home />
          </DefaultLayout>
        </AuthGuard>
      ),
    },
    {
      path: paths.test.root,
      element: (
        <DefaultLayout>
          <Test />
        </DefaultLayout>
      ),
    },
    {
      path: paths.authError.root,
      element: (
        <DefaultLayout>
          <AuthError />
        </DefaultLayout>
      ),
    },
    {
      path: paths.register.root,
      element: (
        <DefaultLayout>
          <Register />
        </DefaultLayout>
      ),
    },

    { path: '*', element: <Navigate to="/404" replace /> },

    ...errorRoutes,
  ]);
}
