import { useRef } from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';

import Collapse from '@mui/material/Collapse';

import Iconify from '../iconify';
import { useSettingsContext } from '../settings';
import { StyledNotistack, StyledBottomSheet } from './styles';

declare module 'notistack' {
  interface VariantOverrides {
    success2: true;
    error2: true;    
  }
}

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function SnackbarProvider({ children }: Props) {
  const settings = useSettingsContext();

  const isRTL = settings.themeDirection === 'rtl';

  const notistackRef = useRef<any>(null);

  return (
    <NotistackProvider
      ref={notistackRef}
      maxSnack={5}
      preventDuplicate
      autoHideDuration={3000}
      TransitionComponent={isRTL ? Collapse : undefined}
      variant="success" // Set default variant
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      iconVariant={{
        success: (
          <Iconify icon="mingcute:check-fill" minWidth={16} minHeight={16} />
        ),
        warning: (
          <Iconify icon="ph:warning-bold" minWidth={16} minHeight={16} />
        ),
        error: (
          <Iconify icon="iconamoon:close-bold" minWidth={16} minHeight={16} />
        ),
        success2: (
          <Iconify icon="ph:check-square-fill" minWidth={34} minHeight={34} sx={{ color: (theme) => theme.palette.blueBrand[150] }} />
        ),
        error2: (
          <Iconify icon="uiw:close-square" minWidth={34} minHeight={34} sx={{ color: (theme) => theme.palette.redBrand[300] }} />
        ),
      }}
      Components={{
        default: StyledBottomSheet,
        info: StyledNotistack,
        success: StyledNotistack,
        warning: StyledNotistack,
        error: StyledNotistack,
        success2: StyledNotistack,
        error2: StyledNotistack
      }}
    >
      {children}
    </NotistackProvider>
  );
}
