import axiosRetry from 'axios-retry';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { useAuthStore } from 'src/auth/auth.store';

import { HttpTypes } from './types.http';
import { AxiosConnectionError } from './errors';
import { setAxiosAuthorization } from './axios-authorization-header';

export const api = axios.create({
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosRetry(api, { retries: 3 });

const auth = useAuthStore.getState();

export class AxiosHttpClient implements HttpTypes.HttpClient {
  private readonly url: string = '';

  constructor(
    private readonly baseURL: string,
    private readonly interceptors?: any
  ) {
    useAuthStore.subscribe(
      (state, prevState) => {
        if (state.accessToken !== prevState.accessToken) {
          setAxiosAuthorization(api, state.accessToken!);
        }
      },
    );

    if (this.baseURL) {
      this.url = this.baseURL;
    }

    if (interceptors) {
      this.interceptors?.forEach((interceptor: ['request' | 'response', ...any[]]) => {
        const [type, ...args] = interceptor;
        api.interceptors[type].use(...args);
      });
    }
  }

  async getTokens(refreshTokenParam?: string): Promise<void> {
    const data: HttpTypes.HttpRequest = {
      url: 'auth/new-token',
      method: 'get',
      headers: {
        Authorization: `Bearer ${refreshTokenParam}`,
        'Content-Type': 'application/json',
      },
    };
    try{
      let { headers } = api.defaults;
      headers = {...headers, ...data.headers}
      const response = await api.request({
        url: `${this.url}/${data.url}`,
        method: data.method,
        // @ts-ignore
        headers: { ...headers },
      });
      const { accessToken, refreshToken } = response.data as any;
      if (accessToken && refreshToken) {
        auth.setSession(accessToken, refreshToken);
        setAxiosAuthorization(api, accessToken);
      } else {
        auth.clearSession();
        window.location.pathname = '/auth/authError'
      }
    }catch(error){
      auth.clearSession();
      window.location.pathname = '/auth/authError'
    }
    
  }

  async request(
    data: HttpTypes.HttpRequest,
    retryToGetTokens: boolean = true
  ): Promise<HttpTypes.HttpResponse> {
    let axiosResponse: AxiosResponse;
    try {
      let { headers } = api.defaults;
      headers = {...headers, ...data.headers}
      
      axiosResponse = await api.request({
        url: `${this.url}/${data.url}`,
        method: data.method,
        data: data.body,
        params: data.params,
        responseType: data.responseType,
        // @ts-ignore
        headers: { ...headers },
      });
    } catch (error: any) {
      const errors = error as AxiosError;

      if (error?.response?.status === 401 && auth.refreshToken && retryToGetTokens) {
        try {
          await this.getTokens(auth.refreshToken);
          if (auth.isAuthenticated) {
            return await this.request(data, false);
          }
        } catch (refereshError: any) {
          console.log('[ERROR]', refereshError.message);
        }
      }

      if (!retryToGetTokens) {
        auth.clearSession();
      }
      if (errors.response) {
        axiosResponse = errors?.response;
      } else if (errors.request) {
        axiosResponse = errors?.request;
      } else {
        axiosResponse = AxiosConnectionError as AxiosResponse;
      }
    }
    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  async fakeRequest(
    data: HttpTypes.HttpRequest,
    options: HttpTypes.RequestOptions
  ): Promise<HttpTypes.HttpResponse> {
    const fakeResponse: HttpTypes.HttpResponse = {
      body: options.data,
      statusCode: options.status,
    };

    if (options.delay) {
      await new Promise((resolve) => setTimeout(resolve, options.delay));
    }

    return Promise.resolve(fakeResponse);
  }
}
