import { paths } from "src/routes/paths";

export const useBenefitsBottomsheet = () => {
  const OPTIONS = [
    { title: 'Experiências', imgPath: '/assets/images/benefits/03-experiencias.png', path: paths.experiences.root },
    { title: 'Produtos', imgPath: '/assets/images/benefits/02-produtos.png', path: paths.products.root },
    { title: 'Colecionáveis', imgPath: '/assets/images/benefits/01-colecionaveis.png', path: '/path2' },
    { title: 'Cupons', imgPath: '/assets/images/benefits/04-cupons.png', path: paths.coupons.root },
    { title: 'Promoção', imgPath: '/assets/images/benefits/05-promo.png', path: '/path4' },
    { title: 'Eventos', imgPath: '/assets/images/benefits/06-eventos.png', path: paths.events.root },
  ];
    
  return {
    OPTIONS,
  }
};
